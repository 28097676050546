const initialValues = {
  role: "",
  name: "",
  phone: "",
  email: "",
  emergency_contact: "",
  professional_role: "",
  admissiondate: "",
  rpa_number_registration: "",
  cep: "",
  address: "",
  number: "",
  complement: "",
  neighborhood: "",
  city: "",
  state: "",
  birthdate: "",
  document: "",
  status: 1,
  supervisor_uuid: [],
};

export default initialValues;
