import { useState } from "react";

import MDBox from "shared/components/MDBox";

import MeetingList from "./meeting_list";
import MeetingCreate from "./meeting_create";
import MeetingEdit from "./meeting_edit";

function getStepContent(stepIndex, setMeetingStep, meetingId, setMeetingId) {
  switch (stepIndex) {
    case "list":
      return (
        <MeetingList
          setMeetingStep={setMeetingStep}
          setMeetingId={setMeetingId}
        />
      );

    case "create":
      return <MeetingCreate setMeetingStep={setMeetingStep} />;

    case "edit":
      return (
        <MeetingEdit
          setMeetingStep={setMeetingStep}
          setMeetingId={setMeetingId}
          meetingId={meetingId}
        />
      );

    default:
      return null;
  }
}

function Meetings() {

  const [meetingStep, setMeetingStep] = useState("list");
  const [meetingId, setMeetingId] = useState();

  return (
    <MDBox pb={1}>
      {getStepContent(meetingStep, setMeetingStep, meetingId, setMeetingId)}
    </MDBox>
  );
}

export default Meetings;
