/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
import { useRef } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @fullcalendar components
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

// @mui material components
import Card from "@mui/material/Card";
import { Button, Tooltip, Typography, useTheme } from "@mui/material";

// Custom styles for Calendar
import CalendarRoot from "shared/components/eventCalendar/CalendarRoot";
import MDBox from "shared/components/MDBox";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";
import MDTypography from "shared/components/MDTypography";
import { userLogged } from "utils/essentialData";

function Calendar({ prevMonth, nextMonth, toPage, hidden, goToday, ...rest }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const calendarRef = useRef();
  const theme = useTheme();

  const userRole = userLogged().role;

  const validClassNames = [
    "primary",
    "secondary",
    "ternary",
    // "quartenary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ];

  const buttonsConfig = {
    previousButton: {
      text: "ANTERIOR",
      click: () => prevMonth(calendarRef),
    },
    nextButton: {
      text: "PRÓXIMO",
      click: () => nextMonth(calendarRef),
    },
    todayButton: {
      text: "HOJE",
      click: () => goToday(calendarRef),
    },
  };

  const events = rest.events
    ? rest.events.map((el) => ({
        ...el,
        className: validClassNames.find((item) => item === el.className)
          ? `event-${el.className}`
          : "event-info",
      }))
    : [];

  function renderInnerContent(innerProps) {
    const title = innerProps.event.title.split(" - ");
    return (
      <MDBox
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="100%"
      >
        {innerProps.timeText && (
          <MDTypography
            className="fc-event-time"
            sx={{ fontSize: theme.typography.size.xs }}
          >
            {innerProps.timeText}
          </MDTypography>
        )}
        <MDBox>
          <MDBox className="fc-event-title">
            {title.map((el, i) => (
              <MDTypography
                key={i}
                align="center"
                style={{
                  fontSize: theme.typography.size.xxs,
                  color: innerProps.textColor || theme.palette.white.main,
                }}
              >
                {el}
              </MDTypography>
            ))}
          </MDBox>
        </MDBox>
      </MDBox>
    );
  }

  const tooltipEvent = (arg) => (
    <Tooltip
      open={false}
      title={
        <Typography variant="body2" fontWeight="regular" color="info">
          {arg.event.title}
        </Typography>
      }
      arrow
      color="info"
    >
      {renderInnerContent(arg)}
    </Tooltip>
  );

  return (
    <Card style={{ display: hidden === true ? "none" : "block" }}>
      <CalendarRoot p={2} ownerState={{ darkMode }}>
        <FullCalendar
          {...rest}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          events={events}
          height="auto"
          ref={calendarRef}
          customButtons={buttonsConfig}
          eventContent={(arg) => tooltipEvent(arg)}
        />
      </CalendarRoot>
    </Card>
  );
}

Calendar.defaultProps = {
  toPage: () => {},
  prevMonth: () => {},
  nextMonth: () => {},
  goToday: () => {},
  hidden: false,
};

// Typechecking props for the Calendar
Calendar.propTypes = {
  toPage: PropTypes.func,
  prevMonth: PropTypes.func,
  nextMonth: PropTypes.func,
  goToday: PropTypes.func,
  hidden: PropTypes.bool,
};

export default Calendar;
