const mockBehavior = {
  name: "Comportamento teste",
  type: "Intervenção",
  procedure: "Lorem ipsum",
  observation: "Lorem ipsum",
  status: 1,
};

export default mockBehavior;

export const mockBehaviorList = [
  {
    uuid: 1,
    name: "Pansie Lowdiane",
    type: "Intervenção",
    status: 1,
  },
  {
    uuid: 2,
    name: "Frasquito Forlonge",
    type: "Intervenção",
    status: 1,
  },
  {
    uuid: 3,
    name: "Turner Chstney",
    type: "Intervenção",
    status: 1,
  },
  {
    uuid: 4,
    name: "Nerte Quogan",
    type: "Intervenção",
    status: 0,
  },
  {
    uuid: 5,
    name: "Corinna Skerritt",
    type: "Intervenção",
    status: 0,
  },
  {
    uuid: 6,
    name: "Hayley Dellenbrook",
    type: "Linha de base",
    status: 0,
  },
  {
    uuid: 7,
    name: "Mickey Turvey",
    type: "Linha de base",
    status: 0,
  },
  {
    uuid: 8,
    name: "Glori Scatcher",
    type: "Manutenção",
    status: 1,
  },
  {
    uuid: 9,
    name: "Davey Crass",
    type: "Linha de base",
    status: 1,
  },
  {
    uuid: 10,
    name: "Thayne Wixey",
    type: "Manutenção",
    status: 0,
  },
];
