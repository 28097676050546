import { toast } from "react-toastify";
import ErrorSnackbar from "shared/components/snackbar/error";

const config = { autoClose: 10000 };

const toastError = (text, title, array) => {
  const content = text ? text : "Erro interno do servidor.";
  const titleToast = title ? title : "Erro";
  toast.error(
    <ErrorSnackbar
      content={text}
      title={titleToast}
    />,
    config
  );
};

export default toastError;
