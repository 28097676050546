import { isAxiosError } from "axios";
import { CALENDAR_API } from "./calendar";
import customAxios from "../axiosCustom";
import errorStatusCode from "utils/errorStatusCode";

class CalendarService {
  create = async ({
    owner,
    start_date,
    title,
    day_week,
    start_hour,
    end_hour,
    recurrence,
  }) => {
    try {
      const response = await customAxios.post(CALENDAR_API, {
        owner,
        start_date,
        title,
        day_week,
        start_hour,
        end_hour,
        recurrence,
      });

      if (!response) new Error("Não foi possível criar Agenda");

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 422:
            throw new Error(error.response?.data.message);

          default:
            throw new Error("Não foi possível criar Agenda");
        }
      }

      throw new Error(error);
    }
  };

  read = async (uuid) => {
    try {
      const response = await customAxios.get(`${CALENDAR_API}/${uuid}`);

      if (!response) new Error("Não foi possível carregar paciente");

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          default:
            throw new Error("Não foi possível carregar paciente");
        }
      }

      throw new Error(error);
    }
  };

  list = async (filter = "") => {
    try {
      const response = await customAxios.get(`${CALENDAR_API}${filter}`);

      if (!response) new Error("Não foi possível listar calendário");

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 404:
            throw new Error("Não foi possível listar calendário");

          default:
            throw new Error("Não foi possível listar calendário");
        }
      }

      throw new Error(error);
    }
  };

  edit = async (
    uuid,
    { title, start_hour, end_hour, therapist_uuid, patient_uuid }
  ) => {
    try {
      const response = await customAxios.put(`${CALENDAR_API}/${uuid}`, {
        title,
        start_hour,
        end_hour,
        therapist_uuid,
        patient_uuid,
      });

      if (!response) new Error("Não foi possível editar agenda");

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 422:
            throw new Error(error.response?.data.message);

          default:
            throw new Error("Não foi possível editar agenda");
        }
      }

      throw new Error(error);
    }
  };

  delete = async (uuid, type) => {
    try {
      const response = await customAxios.delete(
        `${CALENDAR_API}/${uuid}?exclude=${type}`
      );

      if (!response) new Error("Não foi possível apagar agenda");

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          default:
            throw new Error("Não foi possível apagar agenda");
        }
      }

      throw new Error(error);
    }
  };
}

export default new CalendarService();
