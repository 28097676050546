import { validationCPF } from "utils";
import * as Yup from "yup";

const validations = [
  Yup.object().shape({
    role: Yup.number().required("O perfil é obrigatório").nullable(),
    name: Yup.string().required("O nome é obrigatório"),
    phone: Yup.string().required("O telefone é obrigatório"),
    email: Yup.string()
      .email("Email inválido")
      .required("O email é obrigatório"),
    emergency_contact: Yup.string().required(
      "O contato emergencial é obrigatório"
    ),
    professional_role: Yup.string().required(
      "A formação profissional é obrigatória"
    ),
    admissiondate: Yup.date().required("A data de admissão é obrigatória"),
    // rpa_number_registration: Yup.string().required(
    //   "O número de Registro para RPA é obrigatório"
    // ),
    cep: Yup.string().required("O CEP é obrigatório"),
    address: Yup.string().required("O logradouro é obrigatório"),
    number: Yup.number().required("O número é obrigatório"),
    // complement: Yup.string().required("O complemento é obrigatório"),
    neighborhood: Yup.string().required("O bairro é obrigatório"),
    city: Yup.string().required("A cidade é obrigatória"),
    state: Yup.string().required("O estado é obrigatório"),
    birthdate: Yup.string().required("A data de nascimento é obrigatória"),
    document: Yup.string()
      .min(14, "O mínimo de caracteres é 14")
      .test("document", "O CPF digitado não é valido", (value, options) => {
        if (value === undefined || value === "") return true;
        if (value && value.length === 14) return validationCPF(value);
        return false;
      })
      .required("O CPF é obrigatório"),
    supervisor_uuids: Yup.array().when("role", {
      is: (value) => value && value === 2,
      then: Yup.array()
        .required("O supervisor é obrigatório")
        .min(1, "Selecione ao menos 1 supervisor")
        .nullable(),
    }),
    // supervisor_uuid: Yup.object().when("role", {
    //   is: (value) => value && value === 2,
    //   then: Yup.object().required("O supervisor é obrigatório").nullable(),
    // }),
  }),
];

export const validationsEdit = [
  Yup.object().shape({
    role: Yup.number().required("O perfil é obrigatório").nullable(),
    name: Yup.string().required("O nome é obrigatório"),
    phone: Yup.string().required("O telefone é obrigatório"),
    email: Yup.string()
      .email("Email inválido")
      .required("O email é obrigatório"),
    emergency_contact: Yup.string().required(
      "O contato emergencial é obrigatório"
    ),
    professional_role: Yup.string().required(
      "A formação profissional é obrigatória"
    ),
    admissiondate: Yup.date().required("A data de admissão é obrigatória"),
    // rpa_number_registration: Yup.string().required(
    //   "O número de Registro para RPA é obrigatório"
    // ),
    cep: Yup.string().required("O CEP é obrigatório"),
    address: Yup.string().required("O logradouro é obrigatório"),
    number: Yup.number().required("O número é obrigatório"),
    // complement: Yup.string().required("O complemento é obrigatório"),
    neighborhood: Yup.string().required("O bairro é obrigatório"),
    city: Yup.string().required("A cidade é obrigatória"),
    state: Yup.string().required("O estado é obrigatório"),
    birthdate: Yup.string().required("A data de nascimento é obrigatória"),
    status: Yup.number().required("O status é obrigatório").nullable(),
    document: Yup.string()
      .min(14, "O mínimo de caracteres é 14")
      .test("document", "O CPF digitado não é valido", (value, options) => {
        if (value === undefined || value === "") return true;
        if (value && value.length === 14) return validationCPF(value);
        return false;
      })
      .required("O CPF é obrigatório"),
    supervisor_uuids: Yup.array().when("role", {
      is: (value) => value && value === 2,
      then: Yup.array()
        .required("O supervisor é obrigatório")
        .min(1, "Selecione ao menos 1 supervisor")
        .nullable(),
    }),
    // supervisor_uuid: Yup.object().when("role", {
    //   is: (value) => value && value === 2,
    //   then: Yup.object().required("O supervisor é obrigatório").nullable(),
    // }),
  }),
];

export default validations;
