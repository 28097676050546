import * as Yup from "yup";

export const validationsCalendarSearch = [Yup.object().shape({})];

export const validationsSchedule = [
  Yup.object().shape({
    owner: Yup.object().required("O terapeuta é obrigatório").nullable(),
    day_week: Yup.array()
      .required("O dia da semana é obrigatório")
      .min(1, "Selecione ao menos 1 dia da semana")
      .nullable(),
    start_hour: Yup.string().required("A hora inicial é obrigatória"),
    end_hour: Yup.string().test(
      "end_hour",
      "A hora final deve ser maior que a hora inicial",
      function (value) {
        const { start_hour } = this.parent;
        if (value && start_hour > value) return false;
        return true;
      }
    ),
    // .min(Yup.ref('start_hour'), 'A hora final deve ser maior que a inicial').nullable(),
    recurrence: Yup.string()
      .required("O tipo de recorrência é obrigatório")
      .nullable(),
    // name: Yup.string().required("O nome é obrigatório"),
  }),
];

export const validationsEdit = [
  Yup.object().shape({
    start_hour: Yup.string().required("A hora inicial é obrigatória"),
    end_hour: Yup.string().test(
      "end_hour",
      "A hora final deve ser maior que a hora inicial",
      function (value) {
        const { start_hour } = this.parent;
        if (value && start_hour > value) return false;
        return true;
      }
    ),
    therapist_uuid: Yup.object()
      .required("O terapeuta é obrigatório")
      .nullable(),
    // patient_uuid: Yup.object().required("O paciente é obrigatório").nullable(),
  }),
];

export const validationsDeleteSchedule = [Yup.object().shape({})];

const validations = [Yup.object().shape({})];

export default validations;
