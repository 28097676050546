/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Autocomplete,
  Box,
  Card,
  Chip,
  Grid,
  Icon,
  IconButton,
  createFilterOptions,
  useTheme,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import MDBox from "shared/components/MDBox";
import MDTypography from "shared/components/MDTypography";
import DashboardLayout from "shared/examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "shared/examples/Navbars/DashboardNavbar";
import DataTableApi from "shared/components/tables/DatatableApi";

import MDInput from "shared/components/MDInput";
import { useEffect, useState } from "react";
import MDButton from "shared/components/MDButton";
import AttendanceFoldersService from "shared/services/attendance_folders/attendance_folders.service";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Snackbar from "shared/components/snackbar";
import MDProgress from "shared/components/MDProgress";

import NotificationItem from "shared/examples/Items/NotificationItem";
import PopUp, { dialogButton } from "shared/components/pop_up";
import { translateStatus } from "utils/essentialData";
import FormFieldBase from "shared/components/form_field/form_field_base";
import PopUpBehaviorSummary from "../components/list_summary";
import { mockProgramsList } from "../mocks/mockProgram";
import toastError from "shared/components/snackbar/error/toastError";
import toastSuccess from "shared/components/snackbar/success/toastSuccess";

const summaryData = {
  open: false,
  data: "",
};

function Programs() {
  const { palette } = useTheme();
  const { ternary, quartenary, quintenary, sextenary, white } = palette;

  const { uuid } = useParams();
  const navigate = useNavigate();
  const [dataTable, setDataTable] = useState([]);
  const [rowSelected, setRowSelected] = useState();
  const [filter, setFilter] = useState("");
  const [filterSearch, setFilterSearch] = useState("");

  const [openPopUp, setOpenPopUp] = useState(false);
  const [openPopUpSummary, setOpenPopUpSummary] = useState(summaryData);

  const [loadingData, setLoadingData] = useState(false);
  const [tableLimit, setTableLimit] = useState(10);
  const [tablePage, setTablePage] = useState(1);
  const [tableTotal, setTableTotal] = useState(0);

  const viewPage = (row) => (
    <IconButton
      onClick={() => {
        setOpenPopUpSummary({
          open: true,
          data: row.original,
        });
      }}
    >
      <Icon>people</Icon>
    </IconButton>
  );

  const handleChips = (value) => {
    const role = value;
    let color = ternary.main;
    if (role === 0) color = sextenary.main;
    return (
      <Chip
        label={translateStatus(role).toUpperCase()}
        style={{ backgroundColor: color, color: white.main, width: "100%" }}
      />
    );
  };

  const dataTableData = {
    columns: [
      {
        Header: "Nome do comportamento",
        accessor: "name",
      },
      {
        Header: "Tipo de aplicação",
        accessor: "type",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => handleChips(value),
      },
      {
        Header: "Resumo de atendimentos",
        accessor: "view_attendance",
        width: "17%",
        Cell: ({ cell: { row } }) => viewPage(row),
      },
      {
        Header: "ações",
        accessor: "action",
        width: "15%",
      },
    ],

    rows: dataTable?.data || [],
  };

  const loadUsers = async (page = 1, limit = 5, filter = "") => {
    try {
      setDataTable({
        data: mockProgramsList,
        meta: {
          total: 15,
          per_page: 10,
          current_page: 1,
          last_page: 1,
          first_page: 1,
          first_page_url: "/?page=1",
          last_page_url: "/?page=1",
          next_page_url: null,
          previous_page_url: null,
        },
      });
      setTableTotal(mockProgramsList.length);

      // setLoadingData(true);
      // const filterValue = filter !== "" ? `&filter=${filter}` : "";
      // const res = await AttendanceFoldersService.list(
      //   `?page=${page}&limit=${limit}${filterValue}`
      // );
      // if (res) {
      //   setDataTable(res);
      //   setTableTotal(res?.meta?.total);
      // }
    } catch (e) {
      toastError(e.message);
    } finally {
      setLoadingData(false);
    }
  };

  const handleCancelDelete = () => setOpenPopUp(false);

  const handleApplyDelete = async () => {
    try {
      console.log("teste");
      // await AttendanceFoldersService.delete(rowSelected.original.uuid);
      // window.location.reload();
      // toastSuccess("Agenda criada com sucesso");
    } catch (e) {
      toastError(e.message);
    } finally {
      setOpenPopUp(false);
    }
  };

  const handleChange = (el, value) => {
    if (value === "" || value === null) {
      loadUsers(1, tableLimit);
      setTablePage(1);
      setFilterSearch("");
    }
    setFilter(value);
  };

  const handleClickSearch = () => {
    let value = filter === "Todos" ? "" : filter;
    if (filter === "Inativo") value = 1;
    else if (filter === "Ativo") value = 0;

    setFilterSearch(value);
    setTablePage(1);
    loadUsers(1, tableLimit, value);
  };

  useEffect(
    () => loadUsers(tablePage, tableLimit, filterSearch),
    [tablePage, tableLimit]
  );

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox>
          <MDBox pb={1}>
            <Card>
              <MDBox p={3} lineHeight={1}>
                <MDTypography variant="h5" fontWeight="medium">
                  Filtros
                </MDTypography>
                <Grid container spacing={1} mt={1}>
                  <Grid item xs={6}>
                    <Autocomplete
                      options={["Todos", "Inativo", "Ativo"]}
                      // getOptionLabel={translateStatus}
                      value={filter === "" ? null : filter}
                      isOptionEqualToValue={(option, value) => option === value}
                      onChange={handleChange}
                      renderInput={(params) => (
                        <FormFieldBase
                          {...params}
                          variant="outlined"
                          label="status"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={2} display="flex">
                    <MDButton
                      disabled={!filter}
                      variant="gradient"
                      color="primary"
                      size="small"
                      fullWidth
                      onClick={handleClickSearch}
                    >
                      BUSCAR
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </MDBox>
          <Card>
            <MDBox p={3} lineHeight={1}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <MDTypography variant="h5" fontWeight="medium">
                    Programas
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MDBox width="12rem" ml="auto">
                    <MDButton
                      variant="gradient"
                      color="primary"
                      size="small"
                      fullWidth
                      onClick={() =>
                        navigate(
                          `/pasta-de-atendimentos/programas/${uuid}/create`
                        )
                      }
                    >
                      CRIAR
                    </MDButton>
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
            {loadingData ? (
              <MDProgress
                variant="gradient"
                variantProgress="indeterminate"
                color="primary"
              />
            ) : (
              <DataTableApi
                table={dataTableData}
                onRowSelected={setRowSelected}
                entriesPerPage={{ defaultValue: tableLimit }}
                setTableLimit={setTableLimit}
                tableLimit={tableLimit}
                setTablePage={setTablePage}
                tablePage={tablePage}
                tableTotal={tableTotal}
              >
                <NotificationItem
                  onClick={() => {
                    const progam_uuid = rowSelected.original.uuid;
                    navigate(
                      `/pasta-de-atendimentos/programas/${uuid}/ProgramsEdit/${progam_uuid}`
                    );
                  }}
                  icon={<Icon>create</Icon>}
                  title="Editar"
                  key="option_edit"
                />
                <NotificationItem
                  onClick={() => setOpenPopUp(true)}
                  icon={<Icon>delete</Icon>}
                  title="Deletar"
                  key="option_delete"
                />
              </DataTableApi>
            )}
          </Card>
        </MDBox>
      </DashboardLayout>
      <PopUp
        open={openPopUp}
        onClose={() => setRowSelected()}
        title="Apagar programa"
        contentText="Deseja apagar permanentemente este programa?"
        actions={[
          <MDButton
            color="dark"
            onClick={handleCancelDelete}
            style={dialogButton}
            key="cancel"
          >
            cancelar
          </MDButton>,
          <MDButton
            color="error"
            onClick={handleApplyDelete}
            style={dialogButton}
            key="confirm"
          >
            apagar
          </MDButton>,
        ]}
      />
      <PopUpBehaviorSummary
        open={openPopUpSummary}
        onClose={() => setRowSelected()}
        title="Resumo"
        // contentText="Deseja apagar permanentemente este comportamento?"
        actions={[
          <MDButton
            color="dark"
            onClick={() => setOpenPopUpSummary(summaryData)}
            style={dialogButton}
            key="cancel"
          >
            cancelar
          </MDButton>,
        ]}
      />
    </>
  );
}

export default Programs;
