import { isAxiosError } from "axios";
import { USER_API, USERS_API } from "./attendance_folders";
import customAxios from "../axiosCustom";
import errorStatusCode from "utils/errorStatusCode";

class AttendanceFoldersService {
  create = async ({
    role,
    name,
    phone,
    email,
    emergency_contact,
    professional_role,
    admissiondate,
    rpa_number_registration,
    cep,
    address,
    number,
    complement,
    neighborhood,
    city,
    state,
    birthdate,
    document,
  }) => {
    try {
      const response = await customAxios.post(USER_API, {
        role,
        name,
        phone,
        email,
        emergency_contact,
        professional_role,
        admissiondate,
        rpa_number_registration,
        cep,
        address,
        number,
        complement,
        neighborhood,
        city,
        state,
        birthdate,
        document,
      });

      if (!response) new Error("Não foi possível criar usuário");

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 403:
            throw new Error("CPF já cadastrado");

          default:
            throw new Error("Não foi possível criar usuário");
        }
      }

      throw new Error(error);
    }
  };

  read = async (uuid) => {
    try {
      const response = await customAxios.get(`${USER_API}/${uuid}`);

      if (!response) new Error("Não foi possível carregar usuário");

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          default:
            throw new Error("Não foi possível carregar usuário");
        }
      }

      throw new Error(error);
    }
  };

  list = async (filter = "") => {
    try {
      const response = await customAxios.get(`${USERS_API}${filter}`);

      if (!response) new Error("Não foi possível listar usuários");

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          default:
            throw new Error("Não foi possível listar usuários");
        }
      }

      throw new Error(error);
    }
  };

  edit = async ({
    uuid,
    role,
    name,
    phone,
    email,
    emergency_contact,
    professional_role,
    admissiondate,
    rpa_number_registration,
    cep,
    address,
    number,
    complement,
    neighborhood,
    city,
    state,
    birthdate,
    status,
    document,
  }) => {
    try {
      const response = await customAxios.put(`${USER_API}/${uuid}`, {
        role,
        name,
        phone,
        email,
        emergency_contact,
        professional_role,
        admissiondate,
        rpa_number_registration,
        cep,
        address,
        number,
        complement,
        neighborhood,
        city,
        state,
        birthdate,
        status,
        document,
      });

      if (!response) new Error("Não foi possível editar usuário");

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          default:
            throw new Error("Não foi possível editar usuário");
        }
      }

      throw new Error(error);
    }
  };

  delete = async (uuid) => {
    try {
      const response = await customAxios.delete(`${USER_API}/${uuid}`);

      if (!response) new Error("Não foi possível apagar usuário");

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          default:
            throw new Error("Não foi possível apagar usuário");
        }
      }

      throw new Error(error);
    }
  };

  // behavior
  readBehavior = async (uuid) => {
    try {
      const response = await customAxios.get(`${USER_API}/${uuid}`);

      if (!response) new Error("Não foi possível carregar comportamento");

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          default:
            throw new Error("Não foi possível carregar comportamento");
        }
      }

      throw new Error(error);
    }
  };
}

export default new AttendanceFoldersService();
