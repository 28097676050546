/* eslint-disable react-hooks/exhaustive-deps */
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import AuthService from "shared/services/auth/auth.service";

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [token, setToken] = useState();
  const [keeplogged, setKeeplogged] = useState(false);
  // const history = createBrowserHistory();

  const handleKeepLogged = useCallback((value) => {
    localStorage.setItem("keepLogged", value);
    setKeeplogged(value);
  });

  useEffect(() => {
    const token = localStorage.getItem("TOKEN");
    const isKeepLogged = localStorage.getItem("keepLogged");

    if (token) {
      setToken(JSON.parse(JSON.stringify(token)));
    }
    if (isKeepLogged) {
      setKeeplogged(JSON.parse(isKeepLogged) === true ? true : false);
    }
  }, []);

  const handleLogin = useCallback(async (encode, query) => {
    try {
      const result = await AuthService.auth(encode, query);
      localStorage.setItem("TOKEN", result);

      setToken(result);

      return result;
    } catch (e) {
      throw e;
    }
  }, []);

  const handleLogout = useCallback(() => {
    localStorage.removeItem("TOKEN");

    handleKeepLogged(false);
    setToken(undefined);
  }, []);

  const authenticated = useMemo(() => !!token, [token]);

  const value = useMemo(
    () => ({
      authenticated,
      handleLogin,
      handleLogout,
      handleKeepLogged,
      keeplogged,
      token,
    }),
    [
      authenticated,
      handleLogin,
      handleLogout,
      handleKeepLogged,
      keeplogged,
      token,
    ]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export const useAuthContext = () => {
  const {
    authenticated,
    handleLogin,
    handleLogout,
    handleKeepLogged,
    keeplogged,
    token,
  } = useContext(AuthContext);

  return {
    authenticated,
    handleLogin,
    handleLogout,
    handleKeepLogged,
    keeplogged,
    token,
  };
};
