import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
import { Form, Formik } from "formik";

import {
  Autocomplete,
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
  Slide,
  createFilterOptions,
} from "@mui/material";

import dialog from "assets/theme/components/dialog";
import dialogTitle from "assets/theme/components/dialog/dialogTitle";
import dialogContent from "assets/theme/components/dialog/dialogContent";

import MDButton from "shared/components/MDButton";
import MDTypography from "shared/components/MDTypography";

import {
  handleValue,
  setValue,
  renderInput,
  compareOptionToValue,
} from "shared/components/autocomplete/autocompleteConfigs";
import FormField from "shared/components/form_field";

import initialValues from "../schemas/initial_values";
import { validationsSchedule } from "../schemas/validations";
import FormFieldCheckbox from "shared/components/form_field/form_field_checkbox_icon";
import { recurrenceOptions } from "utils/essentialData";
import { translateRecurrenceOptions } from "utils/essentialData";
import calendarService from "shared/services/calendar/calendar.service";
import toastError from "shared/components/snackbar/error/toastError";
import toastSuccess from "shared/components/snackbar/success/toastSuccess";
import { userLogged } from "utils/essentialData";
import MDBox from "shared/components/MDBox";

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));
export const dialogButton = {
  width: "115px",
};

const weekDaysOptions = ["su", "mo", "tu", "we", "th", "fr", "sa"];

const radioOptionsFile = [
  {
    value: "su",
    label: "D",
  },
  {
    value: "mo",
    label: "S",
  },
  {
    value: "tu",
    label: "T",
  },
  {
    value: "we",
    label: "Q",
  },
  {
    value: "th",
    label: "Q",
  },
  {
    value: "fr",
    label: "S",
  },
  {
    value: "sa",
    label: "S",
  },
];

function CreateSchedule({ open, setOpen, onClose, therapistsOptions }) {
  const [supervisorName, setSupervisorName] = useState("");
  const [initialValuesFromApi, setInitialValuesFromApi] =
    useState(initialValues);

  const filterOptionsRecurrence = createFilterOptions({
    stringify: translateRecurrenceOptions,
  });

  const handleSubmit = async (values, actions) => {
    const data = {
      ...values,
      title: values?.owner?.name,
      start_date: open.data?.date.toISOString(),
      owner: values?.owner?.uuid,
    };

    try {
      await calendarService.create(data);
      toastSuccess("Agenda criada com sucesso");
      actions.setTouched({});
      actions.setSubmitting(false);
      onClose();
    } catch (e) {
      toastError(e.message);
    }
  };

  const getWeekDay = (value) => {
    const formatedValue = value?.split("-").join("/");
    const date = new Date(formatedValue);
    const selectedDay = weekDaysOptions[date.getDay()];
    return selectedDay;
  };

  useEffect(() => {
    if (open.data && open.open) {
      const userName = userLogged()?.name;
      setInitialValuesFromApi({
        ...initialValues,
        day_week: [getWeekDay(open.data.dateStr)],
      });
      setSupervisorName(userName);
    }
  }, [open]);

  const filterActiveUser = (array) => array.filter((v) => v.status === 1);

  return (
    <Dialog
      open={open.open}
      // setOpen={setOpen}
      onClose={onClose}
      TransitionComponent={Transition}
      sx={{ ...dialog, textAlign: "center" }}
    >
      <DialogContent sx={dialogContent}>
        <MDBox sx={{ ...dialogTitle, fontWeight: "bold" }}>Nova Agenda</MDBox>
        <DialogContentText>{supervisorName}</DialogContentText>
        <Formik
          initialValues={initialValuesFromApi ?? initialValues}
          validationSchema={validationsSchedule[0]}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            setFieldValue,
            handleBlur,
          }) => {
            const {
              owner: ownerV,
              day_week: day_weekV,
              start_hour: start_hourV,
              end_hour: end_hourV,
              recurrence: recurrenceV,
            } = values;

            // setFieldValue('day_week', ["su"]);

            return (
              <Form
                id="user-create-form"
                autoComplete="off"
                style={{ overflow: "hidden", margin: "16px" }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12}>
                    <Autocomplete
                      options={
                        therapistsOptions?.data
                          ? filterActiveUser(therapistsOptions?.data)
                          : []
                      }
                      getOptionLabel={(opt) => opt.name}
                      value={handleValue(ownerV)}
                      isOptionEqualToValue={compareOptionToValue}
                      onChange={(e, value) =>
                        setValue(setFieldValue, "owner", value)
                      }
                      renderInput={(params) =>
                        renderInput(
                          "form",
                          params,
                          "owner",
                          "Terapeutas *",
                          handleBlur
                        )
                      }
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormField
                      name="start_hour"
                      label="Hora inicial *"
                      type="time"
                      fieldShrink
                      value={start_hourV}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormField
                      name="end_hour"
                      label="Hora final"
                      type="time"
                      fieldShrink
                      value={end_hourV}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Autocomplete
                      options={recurrenceOptions}
                      getOptionLabel={(opt) => translateRecurrenceOptions(opt)}
                      value={handleValue(recurrenceV)}
                      onChange={(e, value) => {
                        setValue(setFieldValue, "recurrence", value);
                        if (value === "unique")
                          setValue(setFieldValue, "day_week", [
                            getWeekDay(open.data.dateStr),
                          ]);
                      }}
                      renderOption={(props, option) => (
                        <Box component="li" {...props}>
                          {translateRecurrenceOptions(option)}
                        </Box>
                      )}
                      filterOptions={filterOptionsRecurrence}
                      renderInput={(params) =>
                        renderInput(
                          "form",
                          params,
                          "recurrence",
                          "Recorrência *",
                          handleBlur
                        )
                      }
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={12}
                    display="flex"
                    flexDirection="column"
                  >
                    <MDTypography variant="caption" align="left">
                      Dia da semana *
                    </MDTypography>
                    <FormFieldCheckbox
                      name="day_week"
                      value={day_weekV}
                      options={radioOptionsFile}
                      disabledValue={
                        recurrenceV === "unique"
                          ? "all"
                          : getWeekDay(open.data.dateStr)
                      }
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={3} mt={1}>
                  <Grid item xs={6} sm={6}>
                    <MDButton
                      color="dark"
                      onClick={() => setOpen({ ...open, open: false })}
                      style={dialogButton}
                      key="cancel"
                    >
                      cancelar
                    </MDButton>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <MDButton
                      disabled={isSubmitting}
                      variant="gradient"
                      color="primary"
                      type="submit"
                      style={dialogButton}
                      fullWidth
                    >
                      SALVAR
                    </MDButton>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

CreateSchedule.defaultProps = {
  onClose: null,
  therapistsOptions: {},
};

CreateSchedule.propTypes = {
  open: PropTypes.objectOf(PropTypes.any).isRequired,
  setOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  therapistsOptions: PropTypes.objectOf(PropTypes.any),
};

export default CreateSchedule;
