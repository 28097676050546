/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// react-quill components
import ReactQuill from "react-quill";

// react-quill styles
import "react-quill/dist/quill.snow.css";

// Custom styles for the MDEditor
import MDEditorRoot from "shared/components/MDEditor/MDEditorRoot";
import EditorToolbar, {
  modules,
  // formats
} from "./EditorToolbar";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";

function MDEditor(props) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  // const modules = {
  //   toolbar: [
  //     [{ header: [1, 2, 3, 4, 5, false] }],
  //     [{ font: [] }],
  //     [{ color: [] }, { background: [] }],
  //     ["bold", "italic", "underline", "strike", "blockquote", "code-block"],
  //     ["link", "image", "video"],
  //     [
  //       { list: "ordered" },
  //       { list: "bullet" },
  //       { indent: "-1" },
  //       { indent: "+1" },
  //     ],
  //     ["clean"],
  //   ],
  // };

  return (
    <MDEditorRoot ownerState={{ darkMode }}>
      <EditorToolbar />
      <ReactQuill modules={modules} theme="snow" {...props} />
    </MDEditorRoot>
  );
}

export default MDEditor;
