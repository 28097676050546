import { Tab, Tabs, useMediaQuery, useTheme } from '@mui/material';
import MDBox from 'shared/components/MDBox';
import DashboardLayout from 'shared/examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'shared/examples/Navbars/DashboardNavbar';
import { lazy, Suspense, useEffect, useState } from 'react';

import PersonalDataEdit from './components/personal_data';
import Meetings from './components/guidelines_meeting';
import FileList from './components/file';
import FilePICList from './components/file_pic';
import { userLogged } from 'utils/essentialData';
import { useParams } from 'react-router-dom';

// import Anamnesis from "./components/anamnesis";
const Anamnesis = lazy(() => import('./components/anamnesis'));

function getTabs() {
  return [
    'Dados Pessoais',
    'Anamnese',
    'Avaliações',
    'PIC e Relatórios',
    'Orientações e Reuniões',
  ];
}

function getStepContent(stepIndex, setTabValue, disableField) {
  switch (stepIndex) {
    case 0:
      return <PersonalDataEdit />;

    case 1:
      return (
        <Suspense fallback={<p>Carregando...</p>}>
          <Anamnesis setTabValue={setTabValue} disableField={disableField} />
        </Suspense>
      );

    case 2:
      return <FileList />;

    case 3:
      return <FilePICList />;

    case 4:
      return <Meetings />;

    default:
      return null;
  }
}

function PatientEdit() {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { type_view } = useParams();

  const [tabValue, setTabValue] = useState(0);
  const tabs = getTabs();

  const [userRole, setUserRole] = useState();

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  const disableField = (fieldName) => {
    if (type_view == 'ver') return true;
    return false;
  };
  useEffect(() => {
    setUserRole(userLogged()?.role ?? '');
  }, [userRole]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={1} px={2} mb={2} bgColor='primary' borderRadius='lg'>
        <Tabs
          orientation={smallScreen ? 'vertical' : 'horizontal'}
          value={tabValue}
          onChange={handleSetTabValue}
        >
          {tabs.map((label) => (
            <Tab key={label} label={label} />
          ))}
        </Tabs>
      </MDBox>
      <MDBox>{getStepContent(tabValue, setTabValue, disableField)}</MDBox>
    </DashboardLayout>
  );
}

export default PatientEdit;
