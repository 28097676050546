/* eslint-disable react-hooks/exhaustive-deps */
import { Autocomplete, Card } from "@mui/material";
import MDBox from "shared/components/MDBox";
import MDTypography from "shared/components/MDTypography";
import { useEffect, useState } from "react";
import MDButton from "shared/components/MDButton";
import { Form, Formik, ErrorMessage } from "formik";
import FormField from "shared/components/form_field";
import { initialValuesMeeting } from "../../../schemas/initial_values";
import { meetingsValidations } from "../../../schemas/validations";

import MeetingService from "shared/services/meetings/meetings.service";
import PropTypes from "prop-types";
import MDEditor from "shared/components/MDEditor";
import { useParams } from "react-router-dom";
import { meetingTypeOptions } from "utils/essentialData";
import { handleValue } from "shared/components/autocomplete/autocompleteConfigs";
import { renderInput } from "shared/components/autocomplete/autocompleteConfigs";
import toastError from "shared/components/snackbar/error/toastError";
import toastSuccess from "shared/components/snackbar/success/toastSuccess";

function MeetingEdit({ setMeetingStep, setMeetingId, meetingId }) {
  const { uuid } = useParams();
  // const [editorValue, setEditorValue] = useState("");
  const [initialValuesFromApi, setInitialValuesFromApi] = useState();

  const loadMeeting = async () => {
    try {
      const meeting = await MeetingService.read(meetingId);
      setInitialValuesFromApi({
        ...meeting,
        participants: meeting.participants ?? "",
        description: meeting.description ?? "",
        school: meeting.school ?? "",
        type: meeting.type ?? "",
        date: meeting.date ? meeting.date.split("T")[0] : "",
        current_therapist: meeting.current_therapist ?? "",
      });
    } catch (e) {
      toastError(e.message);
    }
  };

  const handleSubmit = async (values, actions) => {
    const { participants, description, school, type, date, current_therapist } =
      values;

    try {
      await MeetingService.edit(
        meetingId,
        participants,
        description,
        school,
        uuid,
        type,
        date,
        current_therapist
      );
      toastSuccess("Orientação editada com sucesso");
      actions.setTouched({});
      actions.setSubmitting(false);
      setMeetingStep("list");
      setMeetingId();
    } catch (e) {
      toastError(e.message);
    }
  };

  useEffect(() => {
    loadMeeting();
  }, []);

  return (
    <Formik
      initialValues={initialValuesFromApi ?? initialValuesMeeting}
      validationSchema={meetingsValidations[0]}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        setFieldValue,
        handleBlur,
        setFieldError,
      }) => {
        const {
          participants: participantsV,
          description: descriptionV,
          school: schoolV,
          // patient_uuid: patient_uuidV,
          type: typeV,
          date: dateV,
          current_therapist: current_therapistV,
        } = values;
        return (
          <Form id="user-create-form" autoComplete="off">
            <Card id="basic-info" sx={{ overflow: "visible", p: 3 }}>
              <MDBox>
                <MDTypography variant="h5">Nova Orientação</MDTypography>
              </MDBox>

              <MDBox>
                <FormField
                  name="participants"
                  label="Nome dos participantes da reunião *"
                  type="text"
                  value={participantsV}
                  error={errors.participants && touched.participants}
                  success={participantsV.length > 0 && !errors.participants}
                />
              </MDBox>

              <MDBox>
                <FormField
                  name="school"
                  label="Nome da escola e série"
                  type="text"
                  value={schoolV}
                  error={errors.school && touched.school}
                  success={schoolV.length > 0 && !errors.school}
                />
              </MDBox>

              <MDBox>
                <FormField
                  name="current_therapist"
                  label="Terapeuta atual *"
                  type="text"
                  value={current_therapistV}
                  error={errors.current_therapist && touched.current_therapist}
                  success={
                    current_therapistV.length > 0 && !errors.current_therapist
                  }
                />
              </MDBox>

              <MDBox>
                <FormField
                  name="date"
                  label="Data da orientação *"
                  type="date"
                  value={dateV}
                  fieldShrink
                  error={errors.date && touched.date}
                  success={dateV.length > 0 && !errors.date}
                />
              </MDBox>

              <MDBox>
                <Autocomplete
                  options={meetingTypeOptions}
                  getOptionLabel={(option) => option}
                  value={handleValue(typeV)}
                  isOptionEqualToValue={(option, value) => option === value}
                  onChange={(e, value) => setFieldValue("type", value)}
                  renderInput={(params) =>
                    renderInput(
                      "form",
                      params,
                      "type",
                      "Tipo da orientação *",
                      handleBlur
                    )
                  }
                />
              </MDBox>
            </Card>

            <Card
              id="basic-info"
              sx={{ overflow: "visible", minHeight: "250px", p: 0, mt: 3 }}
            >
              <MDEditor
                value={descriptionV}
                onChange={(value) => setFieldValue("description", value ?? "")}
                style={{ height: "200px" }}
              />
            </Card>
            <MDBox px={3}>
              <MDTypography
                component="div"
                variant="caption"
                color="error"
                fontWeight="regular"
                mt={0.75}
              >
                <ErrorMessage name="description" />
              </MDTypography>
            </MDBox>

            <MDBox display="flex" p={3} width="24rem" ml="auto">
              <MDButton
                color="secondary"
                size="large"
                fullWidth
                onClick={() => {
                  setMeetingStep("list");
                  setMeetingId();
                }}
              >
                cancelar
              </MDButton>
              <MDButton
                sx={{ ml: 3 }}
                disabled={isSubmitting}
                color="primary"
                type="submit"
                size="large"
                fullWidth
              >
                SALVAR
              </MDButton>
            </MDBox>
          </Form>
        );
      }}
    </Formik>
  );
}

MeetingEdit.defaultProps = {
  setMeetingStep: () => {},
  setMeetingId: () => {},
  meetingId: "",
};

MeetingEdit.propTypes = {
  setMeetingStep: PropTypes.func,
  setMeetingId: PropTypes.func,
  meetingId: PropTypes.string,
};

export default MeetingEdit;
