import { useState } from "react";

import MDBox from "shared/components/MDBox";

import FileListPIC from "./file_list";
import FileCreatePIC from "./file_create";

function getStepContent(stepIndex, setFileStep) {
  switch (stepIndex) {
    case "list":
      return <FileListPIC setFileStep={setFileStep} />;

    case "create":
      return <FileCreatePIC setFileStep={setFileStep} />;

    default:
      return null;
  }
}

function FilesPIC() {
  const [fileStep, setFileStep] = useState("list");

  return <MDBox pb={1}>{getStepContent(fileStep, setFileStep)}</MDBox>;
}

export default FilesPIC;
