import React from "react";

import PropTypes from "prop-types";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Slide,
} from "@mui/material";

import dialog from "assets/theme/components/dialog";
import dialogTitle from "assets/theme/components/dialog/dialogTitle";
import dialogContent from "assets/theme/components/dialog/dialogContent";
import dialogActions from "assets/theme/components/dialog/dialogActions";
import MDBox from "shared/components/MDBox";
import MDTypography from "shared/components/MDTypography";

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));
export const dialogButton = {
  width: "115px",
};

function PopUpBehaviorSummary({
  open,
  onClose,
  title,
  contentText,
  contentComponent,
  actions,
}) {
  return (
    <Dialog
      open={open.open}
      onClose={onClose}
      TransitionComponent={Transition}
      sx={{ ...dialog, textAlign: "center" }}
      fullWidth
    >
      <DialogTitle sx={dialogTitle}>{title}</DialogTitle>
      <DialogContent sx={dialogContent}>
        <MDBox>
          <MDTypography variant="h5" fontWeight="medium">
            Brincar compartilhado
          </MDTypography>
          <MDTypography>Linha de base</MDTypography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <MDTypography>Correta:</MDTypography>
              <MDTypography>100%</MDTypography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <MDTypography>Ind.:</MDTypography>
              <MDTypography>3</MDTypography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <MDTypography>Erro:</MDTypography>
              <MDTypography>0</MDTypography>
            </Grid>
          </Grid>
        </MDBox>

        <MDBox>
          <MDTypography variant="h5" fontWeight="medium">
            Intraverbal
          </MDTypography>
          <MDTypography>Manutenção</MDTypography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <MDTypography>Correta:</MDTypography>
              <MDTypography>100%</MDTypography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <MDTypography>Ind.:</MDTypography>
              <MDTypography>3</MDTypography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <MDTypography>Erro:</MDTypography>
              <MDTypography>0</MDTypography>
            </Grid>
          </Grid>
        </MDBox>

        <MDBox>
          <MDTypography variant="h5" fontWeight="medium">
            Ouvinte-identificar animais
          </MDTypography>
          <MDTypography>Intervenção</MDTypography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={3}>
              <MDTypography>Correta:</MDTypography>
              <MDTypography>100%</MDTypography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDTypography>Ind.:</MDTypography>
              <MDTypography>3</MDTypography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDTypography>Erro:</MDTypography>
              <MDTypography>0</MDTypography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDTypography>Ajuda:</MDTypography>
              <MDTypography>0</MDTypography>
            </Grid>
          </Grid>
        </MDBox>
      </DialogContent>
      <DialogActions sx={{ ...dialogActions, justifyContent: "space-evenly" }}>
        {actions.map((item) => item)}
      </DialogActions>
    </Dialog>
  );
}

PopUpBehaviorSummary.defaultProps = {
  onClose: null,
  contentText: null,
  contentComponent: null,
};

PopUpBehaviorSummary.propTypes = {
  open: PropTypes.objectOf(PropTypes.any).isRequired,
  onClose: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  contentText: PropTypes.string,
  contentComponent: PropTypes.node,
  actions: PropTypes.node.isRequired,
};

export default PopUpBehaviorSummary;
