const errorStatusCode = (statusCode) => {
  switch (statusCode) {
    case 400: // Bad Request
      return "Não foi possível continuar com a operação.";

    case 401: // Unauthorized
      return "Informações incorretas ou sessão inválida.";

    case 403: // Forbidden
      return "Usuário sem permissão suficiente.";

    case 409: // Conflict
      return "Erro interno do servidor";

    case 429: // Too Many Requests
      return "Erro interno do servidor.";

    case 500: // Internal Server Error
      return "Erro interno do servidor.";

    case undefined: // Internal Server Error || err_connection_timed_out
      return "Erro interno do servidor.";

    default:
      return "Operação desconhecida!";
  }
};

export default errorStatusCode;
