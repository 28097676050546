/* eslint-disable react-hooks/exhaustive-deps */
import {
  Autocomplete,
  Card,
  Grid,
  createFilterOptions,
  useTheme,
} from '@mui/material';
import MDBox from 'shared/components/MDBox';
import MDTypography from 'shared/components/MDTypography';
import { useState, useEffect } from 'react';
import MDButton from 'shared/components/MDButton';
import { useParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import FormField from 'shared/components/form_field';
import initialValues from '../../schemas/initial_values';
import { validationsEdit } from '../../schemas/validations';
import {
  setValue,
  renderInput,
  handleValue,
  multipleChips,
} from 'shared/components/autocomplete/autocompleteConfigs';
import PatientsService from 'shared/services/patients/patients.service';
import {
  parseDateFromApi,
  translateStatus,
  ufOptions,
  userLogged,
} from 'utils/essentialData';
import { maskCEP, maskPhone } from 'utils/masks';
import { returnOnlyNumber, calculateAge, getTodayDate } from 'utils';
import toastError from 'shared/components/snackbar/error/toastError';
import { getCep } from 'shared/services/api_utils/api_utils.service';
import { formFieldDDIAdornment } from 'shared/components/form_field/form_field_configs';
import UsersService from 'shared/services/users/users.service';
import toastSuccess from 'shared/components/snackbar/success/toastSuccess';

function PersonalDataEdit() {
  const theme = useTheme();
  const { uuid, type_view } = useParams();
  const [initialValuesFromApi, setInitialValuesFromApi] = useState();
  const [userRole, setUserRole] = useState();

  const [supervisorOptions, setSupervisorOptions] = useState([]);
  const [therapistOptions, setTherapistOptions] = useState([]);

  const filterOptionsStatus = createFilterOptions({
    stringify: (option) => translateStatus(option),
  });

  const loadSupervisorsAndTerapists = async (
    page = 1,
    limit = 200,
    filter = ''
  ) => {
    try {
      const filterValue = filter !== '' ? `&filter=${filter.trim()}` : '';
      const res = await UsersService.list(
        `?status=1&role=1,2&page=${page}&limit=${limit}${filterValue}`
      );
      if (res) {
        const supervisorData = res.data?.filter((v) => v.role === 1);
        const therapistData = res.data?.filter((v) => v.role === 2);
        setSupervisorOptions(supervisorData);
        setTherapistOptions(therapistData);
      }
    } catch (e) {
      toastError(e.message);
    }
  };

  const loadPatient = async () => {
    try {
      const user = await PatientsService.read(uuid);

      setInitialValuesFromApi({
        name: user.name ?? '',
        treatment_started: user.treatment_started
          ? parseDateFromApi(user.treatment_started)
          : '',
        birthdate: user.birthdate ? parseDateFromApi(user.birthdate) : '',
        email: user.email ?? '',
        phone: maskPhone(user.phone) ?? '',
        school: user.school ?? '',
        schooling: user.schooling ?? '',
        school_term: user.school_term ?? '',
        father_name: user.father_name ?? '',
        father_age: user.father_age ?? '',
        father_birthdate: user.father_birthdate
          ? parseDateFromApi(user.father_birthdate)
          : '',
        father_job: user.father_job ?? '',
        mother_name: user.mother_name ?? '',
        mother_age: user.mother_age ?? '',
        mother_birthdate: user.mother_birthdate
          ? parseDateFromApi(user.mother_birthdate)
          : '',
        mother_job: user.mother_job ?? '',
        brothers: user.brothers ?? '',
        cep: maskCEP(user.cep) ?? '',
        address: user.address ?? '',
        number: user.number ?? '',
        complement: user.complement ?? '',
        neighborhood: user.neighborhood ?? '',
        city: user.city ?? '',
        state: user.state ?? '',
        forwarded_by: user.forwarded_by ?? '',
        accompanying_doctor: user.accompanying_doctor ?? '',
        health_insurance: user.health_insurance ?? '',
        service_hours: user.service_hours ?? '',
        status: user.status ?? 1,
        supervisor_uuid: user.supervisor ? user.supervisor : '',
        therapist_uuids: user.therapists ? user.therapists : [],
      });
    } catch (e) {
      toastError(e.message);
    }
  };

  const cepHandler = async (event, setFieldValue) => {
    try {
      const result = await getCep(event);
      if (result !== null && result !== undefined) {
        setFieldValue('address', result.street ? result.street : '');
        setFieldValue(
          'neighborhood',
          result.neighborhood ? result.neighborhood : ''
        );
        setFieldValue('city', result.city ? result.city : '');
        setFieldValue('state', result.state ? result.state : '');
      } else toastError('CEP inválido');
    } catch (e) {
      toastError(e.message);
    }
  };

  const disableField = (fieldName = '') => {
    if (type_view == 'ver') return true;
    return false;
  };

  const handleSubmit = async (values, actions) => {
    const {
      treatment_started,
      supervisor_uuid,
      therapist_uuids,
      father_birthdate,
      mother_birthdate,
    } = values;
    try {
      await PatientsService.edit(uuid, {
        ...values,
        treatment_started: treatment_started || null,
        father_birthdate: father_birthdate || null,
        mother_birthdate: mother_birthdate || null,
        supervisor_uuid: supervisor_uuid.uuid,
        therapist_uuids: therapist_uuids.map((v) => v.uuid),
      });
      actions.setTouched({});
      actions.setSubmitting(false);
      toastSuccess(
        'Paciente editado com sucesso',
        '',
        true,
        '/pacientes',
        'Redirecionar para pacientes'
      );
    } catch (e) {
      if (e.message.includes('<p>')) {
        toastError(<div dangerouslySetInnerHTML={{ __html: e.message }} />);
      } else toastError(e.message);
    }
  };

  useEffect(() => {
    loadSupervisorsAndTerapists();
    setUserRole(userLogged()?.role ?? '');
    loadPatient();
  }, []);

  return (
    <Formik
      initialValues={initialValuesFromApi ?? initialValues}
      validationSchema={validationsEdit[0]}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        setFieldValue,
        handleBlur,
      }) => {
        const {
          name: nameV,
          treatment_started: treatment_startedV,
          birthdate: birthdateV,
          email: emailV,
          phone: phoneV,
          school: schoolV,
          schooling: schoolingV,
          school_term: school_termV,
          father_name: father_nameV,
          father_age: father_ageV,
          father_birthdate: father_birthdateV,
          father_job: father_jobV,
          mother_name: mother_nameV,
          mother_age: mother_ageV,
          mother_birthdate: mother_birthdateV,
          mother_job: mother_jobV,
          brothers: brothersV,
          cep: cepV,
          address: addressV,
          number: numberV,
          complement: complementV,
          neighborhood: neighborhoodV,
          city: cityV,
          state: stateV,
          forwarded_by: forwarded_byV,
          accompanying_doctor: accompanying_doctorV,
          health_insurance: health_insuranceV,
          service_hours: service_hoursV,
          status: statusV,
          supervisor_uuid: supervisor_uuidV,
          therapist_uuids: therapist_uuidsV,
        } = values;
        return (
          <Form id='user-edit-form' autoComplete='off'>
            <Card id='basic-info' sx={{ overflow: 'visible', p: 3 }}>
              <MDBox mb={3}>
                <MDTypography variant='h5'>Editar Paciente</MDTypography>
              </MDBox>

              <MDBox>
                <FormField
                  name='name'
                  disabled={disableField('name')}
                  label='Nome completo *'
                  type='text'
                  value={nameV}
                  error={errors.name && touched.name}
                  success={nameV.length > 0 && !errors.name}
                />
              </MDBox>

              <MDBox>
                <FormField
                  name='treatment_started'
                  disabled={disableField('treatment_started')}
                  fieldShrink
                  label='Data de início do tratamento'
                  type='date'
                  value={treatment_startedV}
                  error={errors.treatment_started && touched.treatment_started}
                  success={
                    treatment_startedV.length > 0 && !errors.treatment_started
                  }
                />
              </MDBox>

              <MDBox>
                <FormField
                  name='birthdate'
                  disabled={disableField('birthdate')}
                  fieldShrink
                  label='Data de nascimento *'
                  type='date'
                  value={birthdateV}
                  error={errors.birthdate && touched.birthdate}
                  success={birthdateV.length > 0 && !errors.birthdate}
                />
              </MDBox>

              <MDBox>
                <FormField
                  name='email'
                  disabled={disableField('email')}
                  label='E-mail'
                  type='email'
                  value={emailV}
                  error={errors.email && touched.email}
                  success={emailV.length > 0 && !errors.email}
                />
              </MDBox>

              <MDBox>
                <FormField
                  name='phone'
                  disabled={disableField('phone')}
                  label='Telefone *'
                  type='text'
                  value={phoneV}
                  onChange={(e) => {
                    const { value } = e.target;
                    setFieldValue('phone', maskPhone(value));
                  }}
                  InputProps={{
                    startAdornment: formFieldDDIAdornment(),
                  }}
                  error={errors.phone && touched.phone}
                  success={phoneV.length > 0 && !errors.phone}
                />
              </MDBox>

              <MDBox>
                <FormField
                  name='school'
                  disabled={disableField('school')}
                  label='Escola *'
                  type='text'
                  value={schoolV}
                  error={errors.school && touched.school}
                  success={schoolV.length > 0 && !errors.school}
                />
              </MDBox>

              <MDBox>
                <FormField
                  name='schooling'
                  disabled={disableField('schooling')}
                  label='Escolaridade'
                  type='text'
                  value={schoolingV}
                  error={errors.schooling && touched.schooling}
                  success={schoolingV.length > 0 && !errors.schooling}
                />
              </MDBox>

              <MDBox>
                <FormField
                  name='school_term'
                  disabled={disableField('school_term')}
                  label='Período *'
                  type='text'
                  value={school_termV}
                  error={errors.school_term && touched.school_term}
                  success={school_termV.length > 0 && !errors.school_term}
                />
              </MDBox>

              <MDBox>
                <FormField
                  name='father_name'
                  disabled={disableField('father_name')}
                  label='Nome do pai *'
                  type='text'
                  value={father_nameV}
                  error={errors.father_name && touched.father_name}
                  success={father_nameV.length > 0 && !errors.father_name}
                />
              </MDBox>

              <MDBox>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      name='father_birthdate'
                      disabled={disableField('father_birthdate')}
                      fieldShrink
                      label='Data de nascimento do pai'
                      type='date'
                      value={father_birthdateV}
                      onChange={(e) => {
                        const { value } = e.target;
                        setFieldValue('father_birthdate', value);
                        setFieldValue('father_age', calculateAge(value) || 0);
                      }}
                      error={
                        errors.father_birthdate && touched.father_birthdate
                      }
                      success={
                        father_birthdateV.length > 0 && !errors.father_birthdate
                      }
                      inputProps={{
                        max: getTodayDate(),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      disabled
                      name='father_age'
                      label='Idade do pai'
                      type='number'
                      value={father_ageV}
                      error={errors.father_age && touched.father_age}
                      success={father_ageV.length > 0 && !errors.father_age}
                    />
                  </Grid>
                </Grid>
              </MDBox>

              <MDBox>
                <FormField
                  name='father_job'
                  disabled={disableField('father_job')}
                  label='Profissão do pai'
                  type='text'
                  value={father_jobV}
                  error={errors.father_job && touched.father_job}
                  success={father_jobV.length > 0 && !errors.father_job}
                />
              </MDBox>

              <MDBox>
                <FormField
                  name='mother_name'
                  disabled={disableField('mother_name')}
                  label='Nome da mãe *'
                  type='text'
                  value={mother_nameV}
                  error={errors.mother_name && touched.mother_name}
                  success={mother_nameV.length > 0 && !errors.mother_name}
                />
              </MDBox>

              <MDBox>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      name='mother_birthdate'
                      disabled={disableField('mother_birthdate')}
                      fieldShrink
                      label='Data de nascimento da mãe'
                      type='date'
                      value={mother_birthdateV}
                      onChange={(e) => {
                        const { value } = e.target;
                        setFieldValue('mother_birthdate', value);
                        setFieldValue('mother_age', calculateAge(value) || 0);
                      }}
                      error={
                        errors.mother_birthdate && touched.mother_birthdate
                      }
                      success={
                        mother_birthdateV.length > 0 && !errors.mother_birthdate
                      }
                      inputProps={{
                        max: getTodayDate(),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      disabled
                      name='mother_age'
                      label='Idade da mãe'
                      type='number'
                      value={mother_ageV}
                      error={errors.mother_age && touched.mother_age}
                      success={mother_ageV.length > 0 && !errors.mother_age}
                    />
                  </Grid>
                </Grid>
              </MDBox>

              {/* <MDBox>
                <FormField
                  name='mother_age'
                  disabled={disableField('mother_age')}
                  label='Idade da mãe'
                  type='number'
                  value={mother_ageV}
                  error={errors.mother_age && touched.mother_age}
                  success={mother_ageV.length > 0 && !errors.mother_age}
                />
              </MDBox> */}

              <MDBox>
                <FormField
                  name='mother_job'
                  disabled={disableField('mother_job')}
                  label='Profissão da mãe'
                  type='text'
                  value={mother_jobV}
                  error={errors.mother_job && touched.mother_job}
                  success={mother_jobV.length > 0 && !errors.mother_job}
                />
              </MDBox>

              <MDBox>
                <FormField
                  name='brothers'
                  disabled={disableField('brothers')}
                  label='Irmãos'
                  type='text'
                  value={brothersV}
                  error={errors.brothers && touched.brothers}
                  success={brothersV.length > 0 && !errors.brothers}
                />
              </MDBox>

              <MDBox>
                <FormField
                  name='cep'
                  disabled={disableField('cep')}
                  label='CEP *'
                  type='text'
                  value={cepV}
                  onChange={(e) => {
                    const { value } = e.target;
                    setFieldValue('cep', maskCEP(value));
                  }}
                  onBlur={(e) => {
                    const value = e.target.value;
                    const valueNumber = returnOnlyNumber(value);
                    if (valueNumber && valueNumber.length > 7)
                      cepHandler(valueNumber, setFieldValue);
                  }}
                  error={errors.cep && touched.cep}
                  success={cepV.length > 0 && !errors.cep}
                />
              </MDBox>

              <MDBox>
                <FormField
                  name='address'
                  disabled={disableField('address')}
                  label='Logradouro *'
                  type='text'
                  value={addressV}
                  error={errors.address && touched.address}
                  success={addressV.length > 0 && !errors.address}
                />
              </MDBox>

              <MDBox>
                <FormField
                  name='number'
                  disabled={disableField('number')}
                  label='Número *'
                  type='number'
                  value={numberV}
                  error={errors.number && touched.number}
                  success={numberV.length > 0 && !errors.number}
                />
              </MDBox>

              <MDBox>
                <FormField
                  name='complement'
                  disabled={disableField('complement')}
                  label='Complemento'
                  type='text'
                  value={complementV}
                  error={errors.complement && touched.complement}
                  success={complementV.length > 0 && !errors.complement}
                />
              </MDBox>

              <MDBox>
                <FormField
                  name='neighborhood'
                  disabled={disableField('neighborhood')}
                  label='Bairro *'
                  type='text'
                  value={neighborhoodV}
                  error={errors.neighborhood && touched.neighborhood}
                  success={neighborhoodV.length > 0 && !errors.neighborhood}
                />
              </MDBox>

              <MDBox>
                <FormField
                  name='city'
                  disabled={disableField('city')}
                  label='Cidade *'
                  type='text'
                  value={cityV}
                  error={errors.city && touched.city}
                  success={cityV.length > 0 && !errors.city}
                />
              </MDBox>

              <MDBox>
                <Autocomplete
                  disabled={disableField('state')}
                  options={ufOptions ?? []}
                  getOptionLabel={(option) => option || ''}
                  value={handleValue(stateV)}
                  onChange={(e, value) =>
                    setValue(setFieldValue, 'state', value)
                  }
                  renderInput={(params) =>
                    renderInput('form', params, 'state', 'UF *', handleBlur)
                  }
                />
              </MDBox>

              <MDBox>
                <FormField
                  name='forwarded_by'
                  disabled={disableField('forwarded_by')}
                  label='Encaminhado por'
                  type='text'
                  value={forwarded_byV}
                  error={errors.forwarded_by && touched.forwarded_by}
                  success={forwarded_byV.length > 0 && !errors.forwarded_by}
                />
              </MDBox>

              <MDBox>
                <FormField
                  name='accompanying_doctor'
                  disabled={disableField('accompanying_doctor')}
                  label='Médico que acompanha *'
                  type='text'
                  value={accompanying_doctorV}
                  error={
                    errors.accompanying_doctor && touched.accompanying_doctor
                  }
                  success={
                    accompanying_doctorV.length > 0 &&
                    !errors.accompanying_doctor
                  }
                />
              </MDBox>

              <MDBox>
                <FormField
                  name='health_insurance'
                  disabled={disableField('health_insurance')}
                  label='Convênio *'
                  type='text'
                  value={health_insuranceV}
                  error={errors.health_insurance && touched.health_insurance}
                  success={
                    health_insuranceV.length > 0 && !errors.health_insurance
                  }
                />
              </MDBox>

              <MDBox>
                <FormField
                  name='service_hours'
                  disabled={disableField('service_hours')}
                  label='Carga horária de atendimento *'
                  type='text'
                  value={service_hoursV}
                  error={errors.service_hours && touched.service_hours}
                  success={service_hoursV.length > 0 && !errors.service_hours}
                />
              </MDBox>

              {/* {userRole === 99 && (
                <MDBox>
                  <Autocomplete
                    options={statusOptions}
                    disabled={disableField("status")}
                    getOptionLabel={translateStatus}
                    value={handleValue(statusV)}
                    isOptionEqualToValue={(option, value) => option === value}
                    onChange={(e, value) => setFieldValue("status", value)}
                    filterOptions={filterOptionsStatus}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        {translateStatus(option)}
                      </Box>
                    )}
                    renderInput={(params) =>
                      renderInput(
                        "form",
                        params,
                        "status",
                        "Status *",
                        handleBlur
                      )
                    }
                  />
                </MDBox>
              )} */}

              <MDBox>
                <Autocomplete
                  disabled={disableField('supervisor_uuid')}
                  options={supervisorOptions}
                  freeSolo
                  getOptionLabel={(option) => option.name}
                  value={handleValue(supervisor_uuidV)}
                  isOptionEqualToValue={(option, value) =>
                    option.uuid === value.uuid
                  }
                  onChange={(e, value) =>
                    setFieldValue('supervisor_uuid', value)
                  }
                  renderInput={(params) =>
                    renderInput(
                      'form',
                      params,
                      'supervisor_uuid',
                      'Nome do supervisor *',
                      handleBlur
                    )
                  }
                />
              </MDBox>

              <MDBox>
                <Autocomplete
                  multiple
                  freeSolo
                  disabled={disableField('therapist_uuids')}
                  options={therapistOptions}
                  getOptionLabel={(option) => option?.name}
                  value={therapist_uuidsV || ''}
                  isOptionEqualToValue={(option, value) =>
                    option.uuid === value.uuid
                  }
                  onChange={(e, value) =>
                    setFieldValue('therapist_uuids', value)
                  }
                  renderTags={(value, getTagProps) =>
                    multipleChips(value, getTagProps, 'name', theme)
                  }
                  renderInput={(params) =>
                    renderInput(
                      'form',
                      params,
                      'therapist_uuids',
                      'Terapeutas *',
                      handleBlur
                    )
                  }
                />
              </MDBox>

              {type_view == 'editar' && (
                <MDBox mt={3} px={3} width='12rem' ml='auto'>
                  <MDButton
                    disabled={isSubmitting}
                    color='primary'
                    type='submit'
                    size='large'
                    fullWidth
                  >
                    SALVAR
                  </MDButton>
                </MDBox>
              )}
            </Card>
          </Form>
        );
      }}
    </Formik>
  );
}

export default PersonalDataEdit;
