/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Autocomplete,
  Box,
  Card,
  Chip,
  Grid,
  Icon,
  IconButton,
  createFilterOptions,
  useTheme,
} from "@mui/material";
import MDBox from "shared/components/MDBox";
import MDTypography from "shared/components/MDTypography";
import DashboardLayout from "shared/examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "shared/examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import MDButton from "shared/components/MDButton";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Snackbar from "shared/components/snackbar";
import { ErrorMessage, FieldArray, Form, Formik } from "formik";
import FormField from "shared/components/form_field";
import {
  initialValuesGoals,
  programInitialValues,
} from "../../schemas/initial_values";
import { programValidations } from "../../schemas/validations";
import {
  setValue,
  renderInput,
  handleValue,
  multipleChips,
} from "shared/components/autocomplete/autocompleteConfigs";
import AttendanceFoldersService from "shared/services/attendance_folders/attendance_folders.service";
import toastError from "shared/components/snackbar/error/toastError";
import mockUser from "../../mocks/mockBehavior";
import UsersService from "shared/services/users/users.service";
import PopUp from "shared/components/pop_up";
import { dialogButton } from "shared/components/pop_up";
import {
  programHelpTypeOptions,
  programTypeOptions,
} from "utils/essentialData";

function ProgramsCreate() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [mockData, setMockData] = useState();

  const [therapistOptions, setTherapistOptions] = useState([]);
  const [tableLimit, setTableLimit] = useState(10);
  const [tablePage, setTablePage] = useState(1);

  // popup
  const [openPopUp, setOpenPopUp] = useState(false);
  const [goal, setGoal] = useState();

  const loadTherapists = async (page = 1, limit = 200, filter = "") => {
    try {
      const filterValue = filter !== "" ? `&filter=${filter.trim()}` : "";
      const res = await UsersService.list(
        `?status=1&role=2&page=${page}&limit=${limit}${filterValue}`
      );
      if (res) setTherapistOptions(res.data);
    } catch (e) {
      toastError(e.message);
    }
  };

  const handleDelete = (item, index) => {
    setGoal({ ...item, goalIndex: index });
    setOpenPopUp(true);
  };

  const handleCancelDelete = () => {
    setOpenPopUp(false);
    setGoal();
  };

  const handleApplyDelete = async (valueV, setFieldValue) => {
    // try {
    //   if (goal.guid) await deleteRegisterActivity(goal.guid);
    //   toastSuccess("Recorrência deletada!");
    let goalsFilter = valueV;
    goalsFilter.splice(goal.goalIndex, 1);
    setFieldValue("goals", goalsFilter);
    // } catch (e) {
    //   toastError(e.message);
    // } finally {
    setGoal();
    handleCancelDelete();
    // }
  };

  const handleSubmit = async (values, actions) => {
    // const { name, document, email, role, birthdate } = values;
    console.log(values);
    // try {
    //   await AttendanceFoldersService.create(values);
    //   toastSuccess("Agenda criada com sucesso");
    //   actions.setTouched({});
    //   actions.setSubmitting(false);
    //   navigate("/usuarios");
    // } catch (e) {
    //   toastError(e.message);
    // }
  };

  useEffect(() => {
    loadTherapists();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Formik
        initialValues={mockData ?? programInitialValues}
        validationSchema={programValidations[0]}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          setFieldValue,
          handleBlur,
        }) => {
          const {
            name: nameV,
            type: typeV,
            help_type: help_typeV,
            procedure: procedureV,
            observation: observationV,
            therapist_uuid: therapist_uuidV,
            goals: goalsV,
          } = values;
          return (
            <Form id="user-create-form" autoComplete="off">
              <Card id="basic-info" sx={{ overflow: "visible" }}>
                <MDBox p={3}>
                  <MDTypography variant="h5">Novo Programa</MDTypography>
                </MDBox>

                <MDBox px={3}>
                  <Autocomplete
                    multiple
                    options={therapistOptions}
                    getOptionLabel={(option) => option?.name}
                    value={therapist_uuidV || ""}
                    isOptionEqualToValue={(option, value) =>
                      option.uuid === value.uuid
                    }
                    onChange={(e, value) =>
                      setFieldValue("therapist_uuid", value)
                    }
                    renderTags={(value, getTagProps) =>
                      multipleChips(value, getTagProps, "name", theme)
                    }
                    renderInput={(params) =>
                      renderInput(
                        "form",
                        params,
                        "therapist_uuid",
                        "Terapeutas *",
                        handleBlur
                      )
                    }
                  />
                </MDBox>

                <MDBox px={3}>
                  <FormField
                    name="name"
                    label="Nome do programa *"
                    type="text"
                    value={nameV}
                    error={errors.name && touched.name}
                    success={nameV.length > 0 && !errors.name}
                  />
                </MDBox>

                <MDBox px={3}>
                  <Autocomplete
                    options={programTypeOptions ?? []}
                    getOptionLabel={(option) => option || ""}
                    value={handleValue(typeV)}
                    onChange={(e, value) =>
                      setValue(setFieldValue, "type", value)
                    }
                    renderInput={(params) =>
                      renderInput(
                        "form",
                        params,
                        "type",
                        "Tipo da aplicação *",
                        handleBlur
                      )
                    }
                  />
                </MDBox>

                <MDBox px={3}>
                  <Autocomplete
                    options={programHelpTypeOptions ?? []}
                    getOptionLabel={(option) => option || ""}
                    value={handleValue(help_typeV)}
                    onChange={(e, value) =>
                      setValue(setFieldValue, "help_type", value)
                    }
                    renderInput={(params) =>
                      renderInput(
                        "form",
                        params,
                        "help_type",
                        "Tipo da ajuda *",
                        handleBlur
                      )
                    }
                  />
                </MDBox>

                <MDBox px={3}>
                  <FormField
                    name="procedure"
                    label="Procedimento"
                    type="text"
                    multiline
                    rows={3}
                    value={procedureV}
                    error={errors.procedure && touched.procedure}
                    success={procedureV.length > 0 && !errors.procedure}
                  />
                </MDBox>

                <MDBox px={3}>
                  <FormField
                    name="observation"
                    label="Observação cadastradas no app"
                    type="text"
                    multiline
                    rows={3}
                    value={observationV}
                    error={errors.observation && touched.observation}
                    success={observationV.length > 0 && !errors.observation}
                  />
                </MDBox>

                <MDBox px={3}>
                  <MDTypography variant="h5">Quais os objetivos?</MDTypography>
                  <FieldArray
                    name="goals"
                    render={(arrayHelpers) => (
                      <>
                        {values.goals &&
                          values.goals.map((item, index) => (
                            <Grid container spacing={3} key={index}>
                              <Grid item xs={12} sm={4}>
                                <FormField
                                  name={`goals[${index}].description`}
                                  label="Escreva aqui um objetivo"
                                  type="text"
                                  value={goalsV[index].description}
                                  InputProps={{
                                    endAdornment: (
                                      <IconButton
                                        sx={{
                                          visibility: goalsV[index].description
                                            ? "visible"
                                            : "hidden",
                                        }}
                                        size="small"
                                        onClick={() =>
                                          setFieldValue(
                                            `goals[${index}].description`,
                                            ""
                                          )
                                        }
                                      >
                                        <Icon>clear</Icon>
                                      </IconButton>
                                    ),
                                  }}
                                />
                              </Grid>

                              <Grid item xs={12} sm={3}>
                                <FormField
                                  name={`goals[${index}].attempt`}
                                  label="Tentativas"
                                  type="number"
                                  value={goalsV[index].attempt}
                                />
                              </Grid>

                              <Grid item xs={12} sm={4}>
                                <FormField
                                  name={`goals[${index}].materials`}
                                  label="Materiais"
                                  type="text"
                                  value={goalsV[index].materials}
                                />
                              </Grid>

                              {values.goals.length > 0 && (
                                <Grid item xs={12} sm={1}>
                                  <IconButton
                                    title="Deletar"
                                    color="error"
                                    onClick={() => handleDelete(item, index)}
                                  >
                                    <Icon>delete</Icon>
                                  </IconButton>
                                </Grid>
                              )}
                            </Grid>
                          ))}
                        <MDBox>
                          <MDTypography
                            component="div"
                            variant="caption"
                            color="error"
                            align="center"
                            fontWeight="regular"
                            mt={0.75}
                          >
                            {typeof errors.goals === "string" ? (
                              <ErrorMessage name="goals" />
                            ) : (
                              <MDBox />
                            )}
                          </MDTypography>
                        </MDBox>
                        <MDBox mb={2}>
                          <MDButton
                            onClick={() =>
                              arrayHelpers.push(initialValuesGoals)
                            }
                            variant="gradient"
                            color="info"
                            mb={2}
                          >
                            Adicionar Objetivo
                          </MDButton>
                        </MDBox>
                      </>
                    )}
                  />
                </MDBox>

                <MDBox display="flex" gap="5px" py={3} px={3} ml="auto">
                  {/* <MDButton
                    color="secondary"
                    type="button"
                    size="large"
                    fullWidth
                    onClick={() => setMockData(mockUser)}
                  >
                    Preencher
                  </MDButton> */}
                  <MDButton
                    disabled={isSubmitting}
                    color="primary"
                    type="submit"
                    size="large"
                    fullWidth
                  >
                    SALVAR
                  </MDButton>
                </MDBox>
              </Card>
              <PopUp
                open={openPopUp}
                onClose={() => {}}
                title="Apagar objetivo"
                contentText="Deseja apagar permanentemente este objetivo?"
                actions={[
                  <MDButton
                    color="dark"
                    name="cancelPopup"
                    onClick={handleCancelDelete}
                    style={dialogButton}
                    key="cancel"
                  >
                    cancelar
                  </MDButton>,
                  <MDButton
                    color="error"
                    name="confirmPopup"
                    onClick={() => handleApplyDelete(goalsV, setFieldValue)}
                    style={dialogButton}
                    key="confirm"
                  >
                    apagar
                  </MDButton>,
                ]}
              />
            </Form>
          );
        }}
      </Formik>
    </DashboardLayout>
  );
}

export default ProgramsCreate;
