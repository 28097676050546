import * as Yup from "yup";

export const behaviorsValidations = [
  Yup.object().shape({
    name: Yup.string().required("O nome é obrigatório"),
    type: Yup.string().required("O tipo de aplicação é obrigatória"),
  }),
];

export const behaviorsValidationsEdit = [
  Yup.object().shape({
    name: Yup.string().required("O nome é obrigatório"),
    type: Yup.string().required("O tipo de aplicação é obrigatória"),
    status: Yup.number().required("O status é obrigatório").nullable(),
  }),
];

// program
export const programValidations = [
  Yup.object().shape({
    therapist_uuid: Yup.array()
      .required("Terapeuta é obrigatório")
      .min(1, "O campo deve ter no mínimo 1 terapeuta selecionado"),
    name: Yup.string().required("O nome é obrigatório"),
    type: Yup.string().required("O tipo de aplicação é obrigatória"),
    help_type: Yup.string().required("O tipo de ajuda é obrigatória"),
    goals: Yup.array()
      .of(
        Yup.object().shape({
          description: Yup.string().required("O objetivo é obrigatório"),
          attempt: Yup.number()
            .positive("A tentativa deve ser positiva")
            .required("A tentativa é obrigatoria"),
          materials: Yup.string().required("O material é obrigatório"),
        })
      )
      .required("O Objetivo é obrigatório")
      .min(1, "Um campo é obrigatorio"),
    // status: Yup.number().required("O status é obrigatório").nullable(),
  }),
];

export const programValidationsEdit = [
  Yup.object().shape({
    therapist_uuid: Yup.array()
      .required("Terapeuta é obrigatório")
      .min(1, "O campo deve ter no mínimo 1 terapeuta selecionado"),
    name: Yup.string().required("O nome é obrigatório"),
    type: Yup.string().required("O tipo de aplicação é obrigatória"),
    help_type: Yup.string().required("O tipo de ajuda é obrigatória"),
    goals: Yup.array()
      .of(
        Yup.object().shape({
          description: Yup.string().required("O objetivo é obrigatório"),
          attempt: Yup.number()
            .required("A tentativa é obrigatoria")
            .positive("deve ser positivo"),
          materials: Yup.string().required("O material é obrigatório"),
        })
      )
      .required("O Objetivo é obrigatório")
      .min(1, "Um campo é obrigatorio"),
    status: Yup.number().required("O status é obrigatório").nullable(),
  }),
];
