const initialValues = {
  name: "",
  type: "",
  procedure: "",
  observation: "",
  status: 1,
};

// programs
export const initialValuesGoals = {
  description: "",
  attempt: 0,
  materials: "",
};

export const programInitialValues = {
  therapist_uuid: [],
  name: "",
  type: "",
  help_type: "",
  procedure: "",
  observation: "",
  status: 1,
  goals: [initialValuesGoals],
};

export default initialValues;
