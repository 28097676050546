import React from "react";

import PropTypes from "prop-types";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@mui/material";

import dialog from "assets/theme/components/dialog";
import dialogTitle from "assets/theme/components/dialog/dialogTitle";
import dialogContent from "assets/theme/components/dialog/dialogContent";
import dialogContentText from "assets/theme/components/dialog/dialogContentText";
import dialogActions from "assets/theme/components/dialog/dialogActions";

import logo from "assets/images/logo-clinica-espasso.png";
import MDBox from "../MDBox";
import MDTypography from "../MDTypography";

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));
export const dialogButton = {
  width: "115px",
};

function PopUpLogout({
  open,
  onClose,
  title,
  contentText,
  contentComponent,
  actions,
}) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      sx={{ ...dialog, textAlign: "center" }}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle sx={dialogTitle}>
        <MDBox component="img" src={logo} alt="logo" width="10rem" />
        <MDTypography color="primary" sx={{ fontWeight: "bold", fontSize: "24px" }}>
          {title}
        </MDTypography>
      </DialogTitle>
      {contentText && (
        <DialogContent sx={dialogContent}>
          <DialogContentText sx={dialogContentText}>
            {contentText}
          </DialogContentText>
          {contentComponent}
        </DialogContent>
      )}
      <DialogActions sx={{ ...dialogActions, justifyContent: "space-evenly" }}>
        {actions}
      </DialogActions>
    </Dialog>
  );
}

PopUpLogout.defaultProps = {
  onClose: null,
  contentText: null,
  contentComponent: null,
};

PopUpLogout.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  contentText: PropTypes.string,
  contentComponent: PropTypes.node,
  actions: PropTypes.node.isRequired,
};

export default PopUpLogout;
