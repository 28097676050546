import { Pagination, PaginationItem, styled, Icon } from "@mui/material";
// import MDPagination from "../MDPagination";
import MDBox from "../MDBox";
import MDInput from "../MDInput";
import MDTypography from "../MDTypography";

const CustomPaginationItem = styled(PaginationItem)(({ theme, selected }) => {
  const { borders, functions, typography, palette } = theme;

  const { borderColor } = borders;
  const { pxToRem } = functions;
  const { fontWeightRegular, size: fontSize } = typography;
  const { light } = palette;

  // width, height, minWidth and minHeight values
  let sizeValue = pxToRem(36);

  return {
    borderColor,
    margin: `0 ${pxToRem(2)}`,
    fontWeight: fontWeightRegular,
    fontSize: fontSize.sm,
    width: sizeValue,
    minWidth: sizeValue,
    height: sizeValue,
    minHeight: sizeValue,
    border: `1px solid ${borderColor}`,
    color: selected ? `${light.main} !important` : "inherit",

    "&:hover, &:focus, &:active": {
      transform: "none",
      opacity: "1 !important",
    },

    "&:hover": {
      backgroundColor: light.main,
      borderColor,
    },
  };
});

const CustomPreviousPageButton = (props) => (
  <CustomPaginationItem icon={<Icon> keyboard_arrow_left </Icon>} {...props} />
);

const CustomNextPageButton = (props) => (
  <CustomPaginationItem icon={<Icon> keyboard_arrow_left </Icon>} {...props} />
);

const CustomInputPagination = ({ currentPage, maxLenght, setTablePage }) => {
  return (
    <MDBox mx={1} display="flex" alignItems="center">
      <MDInput
        type="number"
        inputProps={{
          type: "number",
          min: 1,
          max: maxLenght,
        }}
        value={currentPage}
        onChange={(e, v) => {
          const { value } = e.target;
          if (value !== "" && value !== 0 && value <= maxLenght)
            setTablePage(value);
        }}
      />
      <MDTypography ml={1} variant="body2">
        de {maxLenght} páginas
      </MDTypography>
    </MDBox>
  );
};

export function renderPagination(table, currentPage, setTablePage) {
  const { meta } = table;
  const { last_page } = meta;

  if (last_page > 1 && last_page < 6)
    return (
      <Pagination
        count={last_page}
        page={currentPage}
        color="primary"
        // hidePrevButton
        // hideNextButton
        onChange={(e, v) => setTablePage(v)}
        renderItem={(item) => {
          if (item.type === "previous") {
            return <CustomPreviousPageButton {...item} />;
          }
          if (item.type === "next") {
            return <CustomNextPageButton {...item} />;
          }
          return <CustomPaginationItem {...item} />;
        }}
      />
    );
  else if (last_page > 1 && last_page > 6)
    return (
      <CustomInputPagination
        currentPage={currentPage}
        maxLenght={last_page}
        setTablePage={setTablePage}
      />
    );
  else return <MDBox></MDBox>;
}
