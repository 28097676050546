/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Autocomplete, Card, createFilterOptions } from '@mui/material';
import MDBox from 'shared/components/MDBox';
import MDTypography from 'shared/components/MDTypography';
import DashboardLayout from 'shared/examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'shared/examples/Navbars/DashboardNavbar';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import FormField from 'shared/components/form_field';
import initialValues from '../schemas/initial_values';
import { validationsEdit } from '../schemas/validations';
import {
  setValue,
  renderInput,
  handleValue,
} from 'shared/components/autocomplete/autocompleteConfigs';
import UsersService from 'shared/services/users/users.service';
import {
  parseDateFromApi,
  translateRoleNumberToString,
  translateStatus,
  ufOptions,
} from 'utils/essentialData';
import { maskCEP } from 'utils/masks';
import toastError from 'shared/components/snackbar/error/toastError';
import toastSuccess from 'shared/components/snackbar/success/toastSuccess';
import MDButton from 'shared/components/MDButton';

function TherapistsView() {
  const { uuid, type_view } = useParams();
  const [initialValuesFromApi, setInitialValuesFromApi] = useState();

  // const filterOptionsRole = createFilterOptions({
  //   stringify: translateRoleNumberToString,
  // });

  // const filterOptionsStatus = createFilterOptions({
  //   stringify: (option) => translateStatus(option),
  // });

  const handleSubmit = async (values, actions) => {
    const { supervisors } = values;
    console.log('submit', values);
    try {
      await UsersService.edit({
        ...values,
        supervisor_uuids: supervisors.map((v) => v.uuid),
        uuid,
      });
      actions.setTouched({});
      actions.setSubmitting(false);
      toastSuccess(
        'Usuário editado com sucesso',
        '',
        true,
        '/usuarios',
        'Redirecionar para usuários'
      );
    } catch (e) {
      toastError(e.message);
    }
  };

  const loadUsers = async () => {
    try {
      const user = await UsersService.read(uuid);

      setInitialValuesFromApi({
        ...user,
        phone: user.phone || '',
        emergency_contact: user.emergency_contact || '',
        professional_role: user.professional_role || '',
        admissiondate: parseDateFromApi(user.admissiondate) || '',
        rpa_number_registration: user.rpa_number_registration || '',
        cep: maskCEP(user.cep) || '',
        address: user.address || '',
        number: user.number || '',
        complement: user.complement || '',
        neighborhood: user.neighborhood || '',
        city: user.city || '',
        state: user.state || '',
        document: user.document || '',
        email: user.email || '',
        birthdate: parseDateFromApi(user.birthdate) || '',
      });
    } catch (e) {
      toastError(e.message);
    }
  };

  const isEditView = () => {
    if (type_view == 'editar') return true;
    return false;
  };

  useEffect(() => {
    loadUsers();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Formik
        initialValues={initialValuesFromApi ?? initialValues}
        validationSchema={validationsEdit[0]}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          setFieldValue,
          handleBlur,
        }) => {
          const {
            role: roleV,
            name: nameV,
            phone: phoneV,
            email: emailV,
            emergency_contact: emergency_contactV,
            professional_role: professional_roleV,
            admissiondate: admissiondateV,
            rpa_number_registration: rpa_number_registrationV,
            cep: cepV,
            address: addressV,
            number: numberV,
            complement: complementV,
            neighborhood: neighborhoodV,
            city: cityV,
            state: stateV,
            document: documentV,
            birthdate: birthdateV,
            status: statusV,
          } = values;

          return (
            <Form id='user-edit-form' autoComplete='off'>
              <Card id='basic-info' sx={{ overflow: 'visible', p: 3 }}>
                <MDBox mb={3}>
                  <MDTypography variant='h5'>Terapeuta</MDTypography>
                </MDBox>

                {/* <MDBox>
                  <Autocomplete
                    disabled
                    options={rolesOptions ?? []}
                    getOptionLabel={translateRoleNumberToString}
                    value={handleValue(roleV)}
                    onChange={(e, value) =>
                      setValue(setFieldValue, "role", value)
                    }
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        {translateRoleNumberToString(option)}
                      </Box>
                    )}
                    filterOptions={filterOptionsRole}
                    renderInput={(params) =>
                      renderInput(
                        "form",
                        params,
                        "role",
                        "Perfil *",
                        handleBlur
                      )
                    }
                  />
                </MDBox> */}

                <MDBox>
                  <FormField
                    disabled={!isEditView()}
                    name='name'
                    label='Nome completo *'
                    type='text'
                    value={nameV}
                  />
                </MDBox>

                <MDBox>
                  <FormField
                    disabled={!isEditView()}
                    name='birthdate'
                    fieldShrink
                    label='Data de nascimento *'
                    type='date'
                    value={birthdateV}
                  />
                </MDBox>

                <MDBox>
                  <FormField
                    disabled={!isEditView()}
                    name='phone'
                    label='Telefone *'
                    type='text'
                    value={phoneV}
                  />
                </MDBox>

                <MDBox>
                  <FormField
                    disabled={!isEditView()}
                    name='email'
                    label='Email *'
                    type='email'
                    value={emailV}
                  />
                </MDBox>

                <MDBox>
                  <FormField
                    disabled={!isEditView()}
                    name='emergency_contact'
                    label='Contato Emergencial *'
                    type='text'
                    value={emergency_contactV}
                  />
                </MDBox>

                <MDBox>
                  <FormField
                    disabled={!isEditView()}
                    name='professional_role'
                    label='Formação Profissional *'
                    type='text'
                    value={professional_roleV}
                  />
                </MDBox>

                <MDBox>
                  <FormField
                    disabled={!isEditView()}
                    fieldShrink
                    name='admissiondate'
                    label='Data de admissão *'
                    type='date'
                    value={admissiondateV}
                  />
                </MDBox>

                <MDBox>
                  <FormField
                    disabled={!isEditView()}
                    name='rpa_number_registration'
                    label='Número de Registro para RPA ou MEI'
                    type='text'
                    value={rpa_number_registrationV}
                  />
                </MDBox>

                <MDBox>
                  <FormField
                    disabled={!isEditView()}
                    name='cep'
                    label='CEP *'
                    type='text'
                    value={cepV}
                    onChange={(e) => {
                      const { value } = e.target;
                      setFieldValue('cep', maskCEP(value));
                    }}
                  />
                </MDBox>

                <MDBox>
                  <FormField
                    disabled={!isEditView()}
                    name='address'
                    label='Logradouro *'
                    type='text'
                    value={addressV}
                  />
                </MDBox>

                <MDBox>
                  <FormField
                    disabled={!isEditView()}
                    name='number'
                    label='Número *'
                    type='number'
                    value={numberV}
                  />
                </MDBox>

                <MDBox>
                  <FormField
                    disabled={!isEditView()}
                    name='complement'
                    label='Complemento *'
                    type='text'
                    value={complementV}
                  />
                </MDBox>

                <MDBox>
                  <FormField
                    disabled={!isEditView()}
                    name='neighborhood'
                    label='Bairro *'
                    type='text'
                    value={neighborhoodV}
                  />
                </MDBox>

                <MDBox>
                  <FormField
                    disabled={!isEditView()}
                    name='city'
                    label='Cidade *'
                    type='text'
                    value={cityV}
                  />
                </MDBox>

                <MDBox>
                  <Autocomplete
                    disabled={!isEditView()}
                    options={ufOptions ?? []}
                    getOptionLabel={(option) => option || ''}
                    value={handleValue(stateV)}
                    onChange={(e, value) =>
                      setValue(setFieldValue, 'state', value)
                    }
                    renderInput={(params) =>
                      renderInput('form', params, 'state', 'UF *', handleBlur)
                    }
                  />
                </MDBox>

                {/* <MDBox>
                  <Autocomplete
                    disabled
                    options={statusOptions}
                    getOptionLabel={translateStatus}
                    value={handleValue(statusV)}
                    isOptionEqualToValue={(option, value) => option === value}
                    onChange={(e, value) => setFieldValue("status", value)}
                    filterOptions={filterOptionsStatus}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        {translateStatus(option)}
                      </Box>
                    )}
                    renderInput={(params) =>
                      renderInput(
                        "form",
                        params,
                        "status",
                        "Status *",
                        handleBlur
                      )
                    }
                  />
                </MDBox> */}

                {isEditView() && (
                  <MDBox pt={3} width='12rem' ml='auto'>
                    <MDButton
                      disabled={isSubmitting}
                      color='primary'
                      type='submit'
                      size='large'
                      fullWidth
                    >
                      SALVAR
                    </MDButton>
                  </MDBox>
                )}
              </Card>
            </Form>
          );
        }}
      </Formik>
    </DashboardLayout>
  );
}

export default TherapistsView;
