/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Autocomplete, Box, Card, createFilterOptions } from "@mui/material";
import MDBox from "shared/components/MDBox";
import MDTypography from "shared/components/MDTypography";
import DashboardLayout from "shared/examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "shared/examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import MDButton from "shared/components/MDButton";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Formik } from "formik";
import FormField from "shared/components/form_field";
import initialValues from "../../schemas/initial_values";
import { behaviorsValidationsEdit } from "../../schemas/validations";
import {
  setValue,
  renderInput,
  handleValue,
} from "shared/components/autocomplete/autocompleteConfigs";
import AttendanceFoldersService from "shared/services/attendance_folders/attendance_folders.service";
import toastError from "shared/components/snackbar/error/toastError";
import mockBehavior from "../../mocks/mockBehavior";
import { translateStatus } from "utils/essentialData";
import { statusOptions } from "utils/essentialData";
import toastSuccess from "shared/components/snackbar/success/toastSuccess";

const typeOptions = ['Linha de base', 'Intervenção', 'Manutenção'];

function BehaviorsEdit() {
  const { uuid, behavior_uuid } = useParams();
  const navigate = useNavigate();
  const [initialValuesFromApi, setInitialValuesFromApi] = useState();

  const filterOptionsStatus = createFilterOptions({
    stringify: (option) => translateStatus(option),
  });

  const loadBehavior = async () => {
    try {
      // const behavior = await AttendanceFoldersService.readBehavior(behavior_uuid);

      setInitialValuesFromApi(mockBehavior);

      // setInitialValuesFromApi({
      //   name: behavior.name ?? "",
      //   type: behavior.type ?? "",
      //   procedure: behavior.procedure ?? "",
      //   observation: behavior.observation ?? "",
      //   status: behavior.status ?? 1,
      // });
    } catch (e) {
      toastError(e.message);
    }
  };

  const handleSubmit = async (values, actions) => {
    // const { name, document, email, role, birthdate } = values;

    try {
      await AttendanceFoldersService.create(values);
      toastSuccess("Agenda criada com sucesso");
      actions.setTouched({});
      actions.setSubmitting(false);
      navigate("/usuarios");
    } catch (e) {
      toastError(e.message);
    }
  };

  useEffect(()=>{
    loadBehavior();
  },[])

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Formik
        initialValues={initialValuesFromApi ?? initialValues}
        validationSchema={behaviorsValidationsEdit[0]}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          setFieldValue,
          handleBlur,
        }) => {
          const {
            name: nameV,
            type: typeV,
            procedure: procedureV,
            observation: observationV,
            status: statusV,
          } = values;
          return (
            <Form id="user-create-form" autoComplete="off">
              <Card id="basic-info" sx={{ overflow: "visible" }}>
                <MDBox p={3}>
                  <MDTypography variant="h5">Editar comportamento</MDTypography>
                </MDBox>

                <MDBox px={3}>
                  <Autocomplete
                    options={statusOptions}
                    getOptionLabel={translateStatus}
                    value={handleValue(statusV)}
                    isOptionEqualToValue={(option, value) => option === value}
                    onChange={(e, value) => setFieldValue("status", value)}
                    filterOptions={filterOptionsStatus}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        {translateStatus(option)}
                      </Box>
                    )}
                    renderInput={(params) =>
                      renderInput(
                        "form",
                        params,
                        "status",
                        "Status *",
                        handleBlur
                      )
                    }
                  />
                </MDBox>

                <MDBox px={3}>
                  <FormField
                    name="name"
                    label="Nome do comportamento *"
                    type="text"
                    value={nameV}
                    error={errors.name && touched.name}
                    success={nameV.length > 0 && !errors.name}
                  />
                </MDBox>

                <MDBox px={3}>
                  <Autocomplete
                    options={typeOptions ?? []}
                    getOptionLabel={(option) => option || ""}
                    value={handleValue(typeV)}
                    onChange={(e, value) =>
                      setValue(setFieldValue, "type", value)
                    }
                    renderInput={(params) =>
                      renderInput("form", params, "type", "Tipo da aplicação *", handleBlur)
                    }
                  />
                </MDBox>

                <MDBox px={3}>
                  <FormField
                    name="procedure"
                    label="Procedimentos"
                    type="text"
                    multiline
                    rows={3}
                    value={procedureV}
                    error={errors.procedure && touched.procedure}
                    success={procedureV.length > 0 && !errors.procedure}
                  />
                </MDBox>

                <MDBox px={3}>
                  <FormField
                    name="observation"
                    label="Observação cadastradas no app"
                    type="text"
                    multiline
                    rows={3}
                    value={observationV}
                    error={errors.observation && touched.observation}
                    success={observationV.length > 0 && !errors.observation}
                  />
                </MDBox>

                <MDBox display="flex" gap="5px" py={3} px={3} ml="auto">
                  <MDButton
                    disabled={isSubmitting}
                    color="primary"
                    type="submit"
                    size="large"
                    fullWidth
                  >
                    SALVAR
                  </MDButton>
                </MDBox>
              </Card>
            </Form>
          );
        }}
      </Formik>
    </DashboardLayout>
  );
}

export default BehaviorsEdit;
