import PropTypes from "prop-types";

import { ErrorMessage, FastField } from "formik";

import MDBox from "shared/components/MDBox";
import MDTypography from "shared/components/MDTypography";
import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { memo } from "react";

function FormFieldRadio({
  name,
  value,
  options,
  fieldVariant,
  direction,
  ...rest
}) {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleDirection = (type) => {
    if (type === "flexDirection") {
      if (smallScreen && direction === "row") return "column";
      else return direction;
    } else {
      if (smallScreen && direction === "row") return "flex-start";
      else return "flex-start";
    }
  };

  return (
    <MDBox
      display="flex"
      flexDirection={handleDirection("flexDirection")}
      alignItems={handleDirection("alignItems")}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl component="fieldset">
            <FastField {...rest} name={name}>
              {({ field }) => (
                <RadioGroup
                  name={name}
                  value={field.value}
                  onChange={field.onChange}
                  row={true}
                  sx={{
                    display: "flex",
                    flexDirection: handleDirection("flexDirection"),
                    alignItems: handleDirection("alignItems"),
                  }}
                >
                  {options?.map((val, i, arr) => (
                    <FormControlLabel
                      {...rest}
                      value={val.value}
                      control={<Radio />}
                      label={
                        <MDTypography variant="body2">{val.label}</MDTypography>
                      }
                      key={i}
                    />
                  ))}
                </RadioGroup>
              )}
            </FastField>
          </FormControl>
        </Grid>
      </Grid>

      <MDBox>
        <MDTypography
          component="div"
          variant="caption"
          color="error"
          fontWeight="regular"
          align="center"
          m={0.75}
        >
          <ErrorMessage name={name} />
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

FormFieldRadio.defaultProps = {
  //   label: "",
  options: [],
  fieldVariant: "standard",
  direction: "row",
};

FormFieldRadio.propTypes = {
  //   label: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.any),
  fieldVariant: PropTypes.string,
  direction: PropTypes.string,
};

export default memo(FormFieldRadio);
