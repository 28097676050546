import { Autocomplete, Card, Grid, useTheme } from '@mui/material';
import MDBox from 'shared/components/MDBox';
import MDTypography from 'shared/components/MDTypography';
import DashboardLayout from 'shared/examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'shared/examples/Navbars/DashboardNavbar';
import { useEffect, useState } from 'react';
import MDButton from 'shared/components/MDButton';
import { Form, Formik } from 'formik';
import FormField from 'shared/components/form_field';
import initialValues from '../schemas/initial_values';
import validations from '../schemas/validations';
import {
  setValue,
  renderInput,
  handleValue,
  multipleChips,
} from 'shared/components/autocomplete/autocompleteConfigs';
import PatientsService from 'shared/services/patients/patients.service';
import { ufOptions } from 'utils/essentialData';
import { maskCEP, maskPhone } from 'utils/masks';
import mockPatient from '../mocks/mockPatient';
import { getCep } from 'shared/services/api_utils/api_utils.service';
import toastError from 'shared/components/snackbar/error/toastError';
import { returnOnlyNumber, calculateAge, getTodayDate } from 'utils';
import { formFieldDDIAdornment } from 'shared/components/form_field/form_field_configs';
import UsersService from 'shared/services/users/users.service';
import toastSuccess from 'shared/components/snackbar/success/toastSuccess';

function PatientCreate() {
  const theme = useTheme();
  const [mockData, setMockData] = useState();

  const [supervisorOptions, setSupervisorOptions] = useState([]);
  const [therapistOptions, setTherapistOptions] = useState([]);

  const loadSupervisorsAndTerapists = async (
    page = 1,
    limit = 200,
    filter = ''
  ) => {
    try {
      const filterValue = filter !== '' ? `&filter=${filter.trim()}` : '';
      const res = await UsersService.list(
        `?status=1&page=${page}&limit=${limit}${filterValue}`
      );
      if (res) {
        const supervisorData = res.data?.filter((v) => v.role === 1);
        const therapistData = res.data?.filter((v) => v.role === 2);
        setSupervisorOptions(supervisorData);
        setTherapistOptions(therapistData);
      }
    } catch (e) {
      toastError(e.message);
    }
  };

  const handleSubmit = async (values, actions) => {
    const {
      treatment_started,
      supervisor_uuid,
      therapist_uuids,
      father_birthdate,
      mother_birthdate,
    } = values;

    try {
      const data = {
        ...values,
        treatment_started: treatment_started || null,
        father_birthdate: father_birthdate || null,
        mother_birthdate: mother_birthdate || null,
        supervisor_uuid: supervisor_uuid.uuid,
        therapist_uuids: therapist_uuids.map((v) => v.uuid),
      };
      await PatientsService.create(data);
      actions.setTouched({});
      actions.setSubmitting(false);
      toastSuccess(
        'Paciente criado com sucesso',
        '',
        true,
        '/pacientes',
        'Redirecionar para pacientes'
      );
    } catch (e) {
      if (e.message.includes('<p>')) {
        toastError(<div dangerouslySetInnerHTML={{ __html: e.message }} />);
      } else toastError(e.message);
    }
  };

  const cepHandler = async (event, setFieldValue) => {
    try {
      const result = await getCep(event);
      if (result !== null && result !== undefined) {
        setFieldValue('address', result.street ? result.street : '');
        setFieldValue(
          'neighborhood',
          result.neighborhood ? result.neighborhood : ''
        );
        setFieldValue('city', result.city ? result.city : '');
        setFieldValue('state', result.state ? result.state : '');
      } else toastError('CEP inválido');
    } catch (e) {
      toastError(e.message);
    }
  };

  useEffect(() => {
    loadSupervisorsAndTerapists();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Formik
        initialValues={mockData ?? initialValues}
        validationSchema={validations[0]}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          setFieldValue,
          handleBlur,
        }) => {
          const {
            name: nameV,
            treatment_started: treatment_startedV,
            birthdate: birthdateV,
            email: emailV,
            phone: phoneV,
            school: schoolV,
            schooling: schoolingV,
            school_term: school_termV,
            father_name: father_nameV,
            father_age: father_ageV,
            father_birthdate: father_birthdateV,
            father_job: father_jobV,
            mother_name: mother_nameV,
            mother_age: mother_ageV,
            mother_birthdate: mother_birthdateV,
            mother_job: mother_jobV,
            brothers: brothersV,
            cep: cepV,
            address: addressV,
            number: numberV,
            complement: complementV,
            neighborhood: neighborhoodV,
            city: cityV,
            state: stateV,
            forwarded_by: forwarded_byV,
            accompanying_doctor: accompanying_doctorV,
            health_insurance: health_insuranceV,
            service_hours: service_hoursV,
            supervisor_uuid: supervisor_uuidV,
            therapist_uuids: therapist_uuidsV,
          } = values;
          return (
            <Form id='user-create-form' autoComplete='off'>
              <Card id='basic-info' sx={{ overflow: 'visible' }}>
                <MDBox p={3}>
                  <MDTypography variant='h5'>Novo Paciente</MDTypography>
                </MDBox>
                <MDBox px={3}>
                  <FormField
                    name='name'
                    label='Nome completo *'
                    type='text'
                    value={nameV}
                    error={errors.name && touched.name}
                    success={nameV.length > 0 && !errors.name}
                  />
                </MDBox>
                <MDBox px={3}>
                  <FormField
                    name='treatment_started'
                    fieldShrink
                    label='Data de início do tratamento'
                    type='date'
                    value={treatment_startedV}
                    error={
                      errors.treatment_started && touched.treatment_started
                    }
                    success={
                      treatment_startedV.length > 0 && !errors.treatment_started
                    }
                  />
                </MDBox>
                <MDBox px={3}>
                  <FormField
                    name='birthdate'
                    fieldShrink
                    label='Data de nascimento *'
                    type='date'
                    value={birthdateV}
                    error={errors.birthdate && touched.birthdate}
                    success={birthdateV.length > 0 && !errors.birthdate}
                  />
                </MDBox>
                <MDBox px={3}>
                  <FormField
                    name='email'
                    label='E-mail'
                    type='email'
                    value={emailV}
                    error={errors.email && touched.email}
                    success={emailV.length > 0 && !errors.email}
                  />
                </MDBox>
                <MDBox px={3}>
                  <FormField
                    name='phone'
                    label='Telefone *'
                    type='text'
                    value={phoneV}
                    onChange={(e) => {
                      const { value } = e.target;
                      setFieldValue('phone', maskPhone(value));
                    }}
                    error={errors.phone && touched.phone}
                    success={phoneV.length > 0 && !errors.phone}
                    InputProps={{
                      startAdornment: formFieldDDIAdornment(),
                    }}
                  />
                </MDBox>
                <MDBox px={3}>
                  <FormField
                    name='school'
                    label='Escola *'
                    type='text'
                    value={schoolV}
                    error={errors.school && touched.school}
                    success={schoolV.length > 0 && !errors.school}
                  />
                </MDBox>
                <MDBox px={3}>
                  <FormField
                    name='schooling'
                    label='Escolaridade'
                    type='text'
                    value={schoolingV}
                    error={errors.schooling && touched.schooling}
                    success={schoolingV.length > 0 && !errors.schooling}
                  />
                </MDBox>
                <MDBox px={3}>
                  <FormField
                    name='school_term'
                    label='Período *'
                    type='text'
                    value={school_termV}
                    error={errors.school_term && touched.school_term}
                    success={school_termV.length > 0 && !errors.school_term}
                  />
                </MDBox>
                <MDBox px={3}>
                  <FormField
                    name='father_name'
                    label='Nome do pai *'
                    type='text'
                    value={father_nameV}
                    error={errors.father_name && touched.father_name}
                    success={father_nameV.length > 0 && !errors.father_name}
                  />
                </MDBox>
                <MDBox px={3}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <FormField
                        name='father_birthdate'
                        fieldShrink
                        label='Data de nascimento do pai'
                        type='date'
                        value={father_birthdateV}
                        onChange={(e) => {
                          const { value } = e.target;
                          setFieldValue('father_birthdate', value);
                          setFieldValue('father_age', calculateAge(value) || 0);
                        }}
                        error={
                          errors.father_birthdate && touched.father_birthdate
                        }
                        success={
                          father_birthdateV.length > 0 &&
                          !errors.father_birthdate
                        }
                        inputProps={{
                          max: getTodayDate(),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormField
                        disabled
                        name='father_age'
                        label='Idade do pai'
                        type='number'
                        value={father_ageV}
                        error={errors.father_age && touched.father_age}
                        success={father_ageV.length > 0 && !errors.father_age}
                      />
                    </Grid>
                  </Grid>
                </MDBox>
                <MDBox px={3}>
                  <FormField
                    name='father_job'
                    label='Profissão do pai'
                    type='text'
                    value={father_jobV}
                    error={errors.father_job && touched.father_job}
                    success={father_jobV.length > 0 && !errors.father_job}
                  />
                </MDBox>
                <MDBox px={3}>
                  <FormField
                    name='mother_name'
                    label='Nome da mãe *'
                    type='text'
                    value={mother_nameV}
                    error={errors.mother_name && touched.mother_name}
                    success={mother_nameV.length > 0 && !errors.mother_name}
                  />
                </MDBox>
                <MDBox px={3}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <FormField
                        name='mother_birthdate'
                        fieldShrink
                        label='Data de nascimento da mãe'
                        type='date'
                        value={mother_birthdateV}
                        onChange={(e) => {
                          const { value } = e.target;
                          setFieldValue('mother_birthdate', value);
                          setFieldValue('mother_age', calculateAge(value) || 0);
                        }}
                        error={
                          errors.mother_birthdate && touched.mother_birthdate
                        }
                        success={
                          mother_birthdateV.length > 0 &&
                          !errors.mother_birthdate
                        }
                        inputProps={{
                          max: getTodayDate(),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormField
                        disabled
                        name='mother_age'
                        label='Idade da mãe'
                        type='number'
                        value={mother_ageV}
                        error={errors.mother_age && touched.mother_age}
                        success={mother_ageV.length > 0 && !errors.mother_age}
                      />
                    </Grid>
                  </Grid>
                </MDBox>
                <MDBox px={3}>
                  <FormField
                    name='mother_job'
                    label='Profissão da mãe'
                    type='text'
                    value={mother_jobV}
                    error={errors.mother_job && touched.mother_job}
                    success={mother_jobV.length > 0 && !errors.mother_job}
                  />
                </MDBox>
                <MDBox px={3}>
                  <FormField
                    name='brothers'
                    label='Irmãos'
                    type='text'
                    value={brothersV}
                    error={errors.brothers && touched.brothers}
                    success={brothersV.length > 0 && !errors.brothers}
                  />
                </MDBox>
                <MDBox px={3}>
                  <FormField
                    name='cep'
                    label='CEP *'
                    type='text'
                    value={cepV}
                    onChange={(e) => {
                      const { value } = e.target;
                      setFieldValue('cep', maskCEP(value));
                    }}
                    onBlur={(e) => {
                      const value = e.target.value;
                      const valueNumber = returnOnlyNumber(value);
                      if (valueNumber && valueNumber.length > 7)
                        cepHandler(valueNumber, setFieldValue);
                    }}
                    error={errors.cep && touched.cep}
                    success={cepV.length > 0 && !errors.cep}
                  />
                </MDBox>
                <MDBox px={3}>
                  <FormField
                    name='address'
                    label='Logradouro *'
                    type='text'
                    value={addressV}
                    error={errors.address && touched.address}
                    success={addressV.length > 0 && !errors.address}
                  />
                </MDBox>
                <MDBox px={3}>
                  <FormField
                    name='number'
                    label='Número *'
                    type='number'
                    value={numberV}
                    error={errors.number && touched.number}
                    success={numberV.length > 0 && !errors.number}
                  />
                </MDBox>
                <MDBox px={3}>
                  <FormField
                    name='complement'
                    label='Complemento'
                    type='text'
                    value={complementV}
                    error={errors.complement && touched.complement}
                    success={complementV.length > 0 && !errors.complement}
                  />
                </MDBox>
                <MDBox px={3}>
                  <FormField
                    name='neighborhood'
                    label='Bairro *'
                    type='text'
                    value={neighborhoodV}
                    error={errors.neighborhood && touched.neighborhood}
                    success={neighborhoodV.length > 0 && !errors.neighborhood}
                  />
                </MDBox>
                <MDBox px={3}>
                  <FormField
                    name='city'
                    label='Cidade *'
                    type='text'
                    value={cityV}
                    error={errors.city && touched.city}
                    success={cityV.length > 0 && !errors.city}
                  />
                </MDBox>
                <MDBox px={3}>
                  <Autocomplete
                    options={ufOptions ?? []}
                    getOptionLabel={(option) => option || ''}
                    value={handleValue(stateV)}
                    onChange={(e, value) =>
                      setValue(setFieldValue, 'state', value)
                    }
                    renderInput={(params) =>
                      renderInput('form', params, 'state', 'UF *', handleBlur)
                    }
                  />
                </MDBox>
                <MDBox px={3}>
                  <FormField
                    name='forwarded_by'
                    label='Encaminhado por'
                    type='text'
                    value={forwarded_byV}
                    error={errors.forwarded_by && touched.forwarded_by}
                    success={forwarded_byV.length > 0 && !errors.forwarded_by}
                  />
                </MDBox>
                <MDBox px={3}>
                  <FormField
                    name='accompanying_doctor'
                    label='Médico que acompanha *'
                    type='text'
                    value={accompanying_doctorV}
                    error={
                      errors.accompanying_doctor && touched.accompanying_doctor
                    }
                    success={
                      accompanying_doctorV.length > 0 &&
                      !errors.accompanying_doctor
                    }
                  />
                </MDBox>
                <MDBox px={3}>
                  <FormField
                    name='health_insurance'
                    label='Convênio *'
                    type='text'
                    value={health_insuranceV}
                    error={errors.health_insurance && touched.health_insurance}
                    success={
                      health_insuranceV.length > 0 && !errors.health_insurance
                    }
                  />
                </MDBox>
                <MDBox px={3}>
                  <FormField
                    name='service_hours'
                    label='Carga horária de atendimento *'
                    type='text'
                    value={service_hoursV}
                    error={errors.service_hours && touched.service_hours}
                    success={service_hoursV.length > 0 && !errors.service_hours}
                  />
                </MDBox>

                <MDBox px={3}>
                  <Autocomplete
                    options={supervisorOptions}
                    getOptionLabel={(option) => option.name}
                    value={handleValue(supervisor_uuidV)}
                    isOptionEqualToValue={(option, value) =>
                      option.uuid === value.uuid
                    }
                    onChange={(e, value) =>
                      setFieldValue('supervisor_uuid', value)
                    }
                    renderInput={(params) =>
                      renderInput(
                        'form',
                        params,
                        'supervisor_uuid',
                        'Nome do supervisor *',
                        handleBlur
                      )
                    }
                  />
                </MDBox>

                <MDBox px={3}>
                  <Autocomplete
                    multiple
                    options={therapistOptions}
                    getOptionLabel={(option) => option?.name}
                    value={therapist_uuidsV || ''}
                    isOptionEqualToValue={(option, value) =>
                      option.uuid === value.uuid
                    }
                    onChange={(e, value) =>
                      setFieldValue('therapist_uuids', value)
                    }
                    renderTags={(value, getTagProps) =>
                      multipleChips(value, getTagProps, 'name', theme)
                    }
                    renderInput={(params) =>
                      renderInput(
                        'form',
                        params,
                        'therapist_uuids',
                        'Terapeutas *',
                        handleBlur
                      )
                    }
                  />
                </MDBox>

                <MDBox display='flex' gap='5px' py={3} px={3} ml='auto'>
                  {/* <MDButton
                    color="secondary"
                    type="button"
                    size="large"
                    fullWidth
                    onClick={() => setMockData(mockPatient)}
                  >
                    Preencher
                  </MDButton> */}
                  <MDButton
                    disabled={isSubmitting}
                    color='primary'
                    type='submit'
                    size='large'
                    fullWidth
                  >
                    SALVAR
                  </MDButton>
                </MDBox>
              </Card>
            </Form>
          );
        }}
      </Formik>
    </DashboardLayout>
  );
}

export default PatientCreate;
