// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// Material Dashboard 2 PRO React components
import MDInput from 'shared/components/MDInput';

function FormFieldBase({ label, ...rest }) {
  return (
    <MDInput
      variant="standard"
      label={label}
      fullWidth
      InputLabelProps={{ shrink: true }}
      {...rest}
    />
  );
}

// Setting default values for the props of FormField
FormFieldBase.defaultProps = {
  label: ' ',
};

// Typechecking props for FormField
FormFieldBase.propTypes = {
  label: PropTypes.string,
};

export default FormFieldBase;
