const mockProgram = {
  therapist_uuid: [],
  name: "Comportamento teste",
  type: "Intervenção",
  help_type: "",
  procedure: "Lorem ipsum",
  observation: "Lorem ipsum",
  goals: [{ description: "Objetivo test", attempt: 2, materials: "items" }],
  status: 1,
};

export default mockProgram;

export const mockProgramsList = [
  {
    uuid: 1,
    name: "Temp Guillain",
    type: "Intervenção",
    status: 1,
  },
  {
    uuid: 2,
    name: "Farlee McDugal",
    type: "Linha de base",
    status: 1,
  },
  {
    uuid: 3,
    name: "Shurlocke Stallybrass",
    type: "Intervenção",
    status: 0,
  },
  {
    uuid: 4,
    name: "Hendrik Oseland",
    type: "Intervenção",
    status: 1,
  },
  {
    uuid: 5,
    name: "Maribel De Benedictis",
    type: "Linha de base",
    status: 0,
  },
  {
    uuid: 6,
    name: "Obadiah Perin",
    type: "Intervenção",
    status: 0,
  },
  {
    uuid: 7,
    name: "Ingeberg Wackett",
    type: "Intervenção",
    status: 1,
  },
  {
    uuid: 8,
    name: "Ross Capell",
    type: "Linha de base",
    status: 1,
  },
  {
    uuid: 9,
    name: "Ingunna Taylo",
    type: "Manutenção",
    status: 0,
  },
  {
    uuid: 10,
    name: "Roscoe Blaxland",
    type: "Linha de base",
    status: 0,
  },
];
