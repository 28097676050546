/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Card, Grid, Icon, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import MDBox from 'shared/components/MDBox';
import MDTypography from 'shared/components/MDTypography';
import DashboardLayout from 'shared/examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'shared/examples/Navbars/DashboardNavbar';
import DataTableApi from 'shared/components/tables/DatatableApi';

import MDInput from 'shared/components/MDInput';
import { useEffect, useState } from 'react';
import MDButton from 'shared/components/MDButton';
import UsersService from 'shared/services/users/users.service';
import { useNavigate } from 'react-router-dom';
import MDProgress from 'shared/components/MDProgress';
import toastError from 'shared/components/snackbar/error/toastError';
import NotificationItem from 'shared/examples/Items/NotificationItem';

function Therapists() {
  const navigate = useNavigate();
  const [dataTable, setDataTable] = useState([]);
  const [rowSelected, setRowSelected] = useState();
  const [filter, setFilter] = useState('');
  const [filterSearch, setFilterSearch] = useState('');

  const [loadingData, setLoadingData] = useState(false);
  const [tableLimit, setTableLimit] = useState(10);
  const [tablePage, setTablePage] = useState(1);
  const [tableTotal, setTableTotal] = useState(0);

  const viewTherapist = (row) => (
    <IconButton
      onClick={() => {
        navigate('/terapeutas/ver/' + row.original.uuid);
      }}
    >
      <Icon>visibility</Icon>
    </IconButton>
  );

  const dataTableData = {
    columns: [
      {
        Header: 'nome',
        accessor: 'name',
      },
      {
        Header: 'E-mail',
        accessor: 'email',
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }) => (value === 0 ? 'Inativo' : 'Ativo'),
      },
      {
        Header: 'ações',
        accessor: 'action',
        width: '15%',
        // Cell: ({ cell: { row } }) => viewTherapist(row),
      },
    ],

    rows: dataTable?.data || [],
  };

  const loadUsers = async (page = 1, limit = 5, filter = '') => {
    try {
      setLoadingData(true);
      const filterValue = filter !== '' ? `&filter=${filter.trim()}` : '';
      const res = await UsersService.list(
        `?role=2&page=${page}&limit=${limit}${filterValue}`
      );
      if (res) {
        setDataTable(res);
        setTableTotal(res?.meta?.total);
      }
    } catch (e) {
      toastError(e.message);
    } finally {
      setLoadingData(false);
    }
  };

  const handleChange = (event) => {
    const { value } = event.target;
    if (value === '') {
      loadUsers(1, tableLimit);
      setTablePage(1);
      setFilterSearch('');
    }
    setFilter(value);
  };

  const handleClickSearch = () => {
    setFilterSearch(filter.trim());
    setTablePage(1);
    loadUsers(1, tableLimit, filter);
  };

  useEffect(
    () => loadUsers(tablePage, tableLimit, filterSearch),
    [tablePage, tableLimit]
  );

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox>
          <MDBox pb={1}>
            <Card>
              <MDBox p={3} lineHeight={1}>
                <MDTypography variant='h5' fontWeight='medium'>
                  Filtros
                </MDTypography>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <MDInput
                      type='text'
                      name='filter'
                      value={filter}
                      size='small'
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            sx={{ visibility: filter ? 'visible' : 'hidden' }}
                            size='small'
                            onClick={() =>
                              handleChange({ target: { value: '' } })
                            }
                          >
                            <ClearIcon />
                          </IconButton>
                        ),
                      }}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={2} display='flex'>
                    <MDButton
                      disabled={!filter}
                      variant='gradient'
                      color='primary'
                      size='small'
                      fullWidth
                      onClick={handleClickSearch}
                    >
                      BUSCAR
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </MDBox>
          <Card>
            <MDBox p={3} lineHeight={1}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <MDTypography variant='h5' fontWeight='medium'>
                    Terapeutas
                  </MDTypography>
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                    <MDBox width="12rem" ml="auto">
                      <MDButton
                        variant="gradient"
                        color="primary"
                        size="small"
                        fullWidth
                        onClick={() => navigate("/usuarios/criar")}
                      >
                        CRIAR
                      </MDButton>
                    </MDBox>
                  </Grid> */}
              </Grid>
            </MDBox>
            {loadingData ? (
              <MDProgress
                variant='gradient'
                variantProgress='indeterminate'
                color='primary'
              />
            ) : (
              <DataTableApi
                table={dataTableData}
                onRowSelected={setRowSelected}
                entriesPerPage={{ defaultValue: tableLimit }}
                setTableLimit={setTableLimit}
                tableLimit={tableLimit}
                setTablePage={setTablePage}
                tablePage={tablePage}
                tableTotal={tableTotal}
              >
                {[
                  <NotificationItem
                    onClick={() => {
                      const { uuid } = rowSelected.original;
                      navigate('/terapeutas/ver/' + uuid);
                    }}
                    icon={<Icon>visibility</Icon>}
                    title='Ver'
                    key='option_view'
                  />,
                  <NotificationItem
                    onClick={() => {
                      const { uuid } = rowSelected.original;
                      navigate('/terapeutas/editar/' + uuid);
                    }}
                    icon={<Icon>create</Icon>}
                    title='Editar'
                    key='option_edit'
                  />,
                ]}
              </DataTableApi>
            )}
          </Card>
        </MDBox>
      </DashboardLayout>
    </>
  );
}

export default Therapists;
